@import 'mixins/shadow';

.c4-prevent-flex-height-overflow {
  min-height: 0
}

.c4-grid-container {
  background: $col-backcontrast;
  border-radius: 0.4rem;
  @include default-box-shadow;
}