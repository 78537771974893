@mixin slimInput {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0.3rem;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.3rem 0 0.2rem 0;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75) perspective(100px) translateZ(0.001px);
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0.3rem;
  }

  .mat-form-field {
    width: 100%;
  }
}

// Use on input container since input cannot have containing elements (before/after)
@mixin mat-input-underline($vertical-margin: 0rem, $horizontal-margin: 0rem) {
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    bottom: $vertical-margin;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    border-top-style: solid;
  }

  &:before {
    width: calc(100% - #{$horizontal-margin * 2});
    border-top-width: 0.1rem;
    border-top-color: $col-text-light;
  }

  &:after {
    opacity: 0;
    width: 50%;
    transition-property: width;
    transition-duration: 0.2s;
    border-top-width: 0.2rem;
    border-top-color: $col-primary;
  }

  &:focus-within,
  &:focus {
    &:after {
      opacity: 1;
      width: calc(100% - #{$horizontal-margin * 2});
    }
  }

  input {
    margin-bottom: 0.2rem;
    border: none;
  }
}