.project-config {
  .content {
    position: relative;
  }

  .config-content-container {
    height: 100%;
    overflow-y: auto;
    background-color: $col-backcontrast;

    .config-content {
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 4.8rem;
      min-width: min-content;
    }
  }

  .sticky {
    position: sticky;
    left: 2.4rem;

    .config-title {
      position: sticky;
      left: 2.4rem;
    }
  }

  .content-sticky {
    position: sticky !important;
    left: 0 !important;
  }

  .sticky-right {
    position: sticky;
    right: 2.4rem;
  }

  .slim {
    max-width: 48rem;
  }

  .config-title {
    margin: 0;
    font-size: 1.8rem;
    width: min-content;
    white-space: nowrap;
    display: flex;

    &.with-action {
      width: auto;
      justify-content: space-between;
      align-items: center;
      gap: 1.2rem;

      .mdi.action {
        height: 0;
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        color: $col-text-light;
        cursor: pointer;
      }
    }
  }

  app-toggle-field.title {
    .toggle-field-caption {
      font-size: 1.8rem;
    }
  }

  .config-field-description {
    font-weight: 300;
  }

  .config-group {
    display: flex;
    flex-direction: column;

    &.small-gap {
      gap: 1.2rem;
    }

    &.gap {
      gap: 2.4rem;
    }

    .small-gap-top {
      margin-top: 1.2rem;
    }

    .gap-top {
      margin-top: 2.4rem;
    }
  }

  .mat-form-field.small {
    .mat-form-field-infix {
      width: 12rem;
    }
  }

  .mdi {
    font-size: $fontsize-icon;
  }

  .slim-icon-container {
    .mdi {
      font-size: 1.8rem;
      color: $col-text-light;
    }

    &.clickable .mdi {
      cursor: pointer;
    }
  }

  .table-grid-component-container {
    display: flex;

    @mixin wall {
      content: '';
      z-index: 20; // Watch out for scrollbars
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2.4rem;
      background-color: $col-backcontrast;
      pointer-events: none;
    }

    &::before {
      @include wall;
      left: 0;
    }

    &::after {
      @include wall;
      right: 0;
    }

    .table-grid-component {
      flex: 1;

      .c4-table-grid-header-start,
      .c4-table-grid-header-top.corner {
        left: 2.4rem !important;
      }

      .c4-table-grid-header-end {
        right: 2.4rem !important;
      }
    }
  }
}

.isphone.project-config {
  .slim {
    max-width: unset;
  }

  .config-content-container {
    padding-bottom: 2.4rem;

    .config-content {
      padding: 2.4rem;

      .table-grid-component-container {
        margin: 0 -2.4rem;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    .table-grid-component.matrix {

      .c4-table-grid-header-start,
      .c4-table-grid-header-top.corner {
        left: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .label-stack-container,
        &>span {
          padding-left: 2.4rem;
        }
      }

      .c4-table-grid-header-top.c4-table-grid-header-start::before,
      .c4-table-grid-header-start.c4-table-grid-group-header::before {
        content: " ";
        display: inline-block;
        width: 2.4rem;
      }

      .c4-table-grid-group-header.c4-table-grid-header-start {
        margin-right: 0;
      }

      .c4-table-grid-header-end {
        right: 0 !important;
      }
    }
  }
}