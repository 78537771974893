@use '@angular/material' as mat;
@use 'angular-material-css-vars-legacy/public-util' as mat-css-utilities;

$col-primary: mat-css-utilities.mat-css-color-primary(500);
$col-primary-contrast: mat-css-utilities.mat-css-contrast-color-primary(500);
$col-accent: mat-css-utilities.mat-css-color-accent(500);
$col-accent-contrast: mat-css-utilities.mat-css-contrast-color-accent(500);
$col-warning: mat-css-utilities.mat-css-color-warn(500);

$green-palette: mat.define-palette(mat.$green-palette);
$col-okay: mat.get-color-from-palette($green-palette, 500);
$col-okay-light: mat.get-color-from-palette($green-palette, 200);
$col-excel: mat.get-color-from-palette($green-palette, 800);

$red-palette: mat.define-palette(mat.$red-palette);
$col-error: mat.get-color-from-palette($red-palette, 500);
$col-pdf: mat.get-color-from-palette($red-palette, 700);

$deep-orange-palette: mat.define-palette(mat.$deep-orange-palette);
$col-powerpoint: mat.get-color-from-palette($deep-orange-palette, 700);

$yellow-palette: mat.define-palette(mat.$yellow-palette);
$col-folder: mat.get-color-from-palette($yellow-palette, 600);

$blue-palette: mat.define-palette(mat.$blue-palette);
$col-active: mat.get-color-from-palette($blue-palette, 500);
$col-text-link: mat.get-color-from-palette($blue-palette, 700);
$col-word: mat.get-color-from-palette($blue-palette, 900);

$grey-palette: mat.define-palette(mat.$grey-palette);
$col-background: mat.get-color-from-palette($grey-palette, 100);
$col-backcontrast: #fff;

$col-acrylic-background: mat.get-color-from-palette($grey-palette, 50, 0.3);

$col-text: rgb(var(--text-color));
$col-text-light: rgba(var(--text-color), 0.8);
$col-backdrop: rgba(var(--text-color), 0.4);
$col-border: rgba(var(--text-color), 0.2);
$col-selected: rgba(var(--text-color), 0.12);
$col-hover: rgba(var(--text-color), 0.04);