.cdk-overlay-pane.entity-detail {
  // max-width: 100vw !important;
  // max-height: 100vh !important;
  // min-width: 80vw;
  // min-height: 80vh;
  width: 100vw;
  height: 100vh;
  max-width: 80vw !important;
  max-height: 80vh !important;

  &.full-screen {
    max-width: 100vw !important;
    max-height: 100vh !important;
    padding-top: 0; // env(safe-area-inset-top, 0px);
  }

  @media #{$mat-lt-md} {
    max-width: 100vw !important;
    max-height: 100vh !important;
    padding-top: 0; // env(safe-area-inset-top, 0px);
  }

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
    overflow: hidden;
    // .safe-area {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   padding-top: env(safe-area-inset-top, 0px);
    //   background-color: $col-backcontrast;
    //   width: 100%;
    //   z-index: 1000;
    // }

    .entity-detail-dialog {
      @media #{$mat-lt-sm} {
        font-size: $entity-detail-mobile-font-size;
      }

      .select-with-icon {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        @media #{$mat-lt-sm} {
          justify-content: space-between;
          height: $entity-detail-mobile-row-height;
        }

        .mdi {
          font-size: 1.8rem;
          color: $col-text-light;

          @media #{$mat-lt-sm} {
            font-size: 2.4rem;
          }
        }
      }

      .date-picker-toggle {
        position: absolute;
        right: 0;
        color: $col-text-light;
        cursor: pointer;
      }

      .slim-form-field {
        @media #{$mat-lt-sm} {
          flex: 1;

          .mat-form-field {
            width: 100%;
          }
        }

        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-select-arrow {
            width: 0;
            height: 0;
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-top: 0.4em solid;
            margin: 0 0.2em;
          }

          .mat-form-field-infix {
            display: block;
            position: relative;
            flex: auto;
            min-width: 5em;
            width: 14em;
            border: 0;
            padding: 0 !important;

            .mat-form-field-label-wrapper {
              top: 0;
              padding-top: 0;
            }
          }

          .mat-form-field-underline {
            bottom: 0;
            opacity: 0.5;
            width: auto;
            left: 0;
            right: 1.8rem;

            @media #{$mat-lt-sm} {
              display: none;
            }
          }
        }
      }

      .slim-tree-select {
        width: 14em;

        @media #{$mat-lt-sm} {
          flex: 1;
        }

        .arrow-container {
          top: 50%;
        }

        .p-treeselect {
          margin: 0;
          background: transparent;
          border: none;

          .p-treeselect-label-container {
            border: none;
            margin-right: 1.8rem;

            @media #{$mat-gt-xs} {
              border-bottom: solid 1px $col-selected;
            }

            .p-treeselect-label {
              border: none;
              padding: 0;
            }
          }
        }
      }

      .dialog-header {
        .header-container {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          margin: 0.6rem 1.2rem 1.2rem $scrollbar-width;

          @media #{$mat-lt-sm} {
            margin: 1.2rem 0.6rem 0;
          }

          .entity-edit-title-container {
            font-size: 1.6rem;
            display: flex;
            gap: 0.6rem;
            align-items: center;

            @media #{$mat-lt-sm} {
              font-size: $entity-detail-mobile-font-size + 2px;
            }

            .number {
              display: flex;
              justify-content: center;
              min-width: 1.8rem;
              border-bottom: solid 1px transparent;

              @media #{$mat-lt-sm} {
                min-width: 2.4rem;
              }
            }

            .entity-edit-title-input {
              flex: 1;
              font-size: inherit;
              font-family: inherit;
              border: none;
              border-bottom: solid 1px transparent;
              padding: 0;
              margin: 0;
              background: transparent;

              &:focus {
                border-color: $col-primary;
              }

              &.error {
                border-color: $col-warning;
              }
            }
          }

          .header-row {
            display: flex;
            gap: 0.6rem;

            @media #{$mat-lt-sm} {
              flex-direction: column;

              >*:nth-child(2n + 1) {
                background-color: $col-background;
              }
            }
          }
        }
      }

      .dialog-sub-header {
        .sub-header-container {
          flex: 1;
          display: flex;
          padding: 1.2rem 16px;

          @media #{$mat-lt-sm} {
            padding: 0;
            flex-direction: column;
          }

          .form-grid {
            gap: 1.2rem;
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-auto-flow: column;
            grid-template-rows: 1fr 1fr;
            align-items: center;

            @media #{$mat-lt-sm} {
              grid-template-columns: auto;
              grid-template-rows: repeat(4, $entity-detail-mobile-row-height);
              align-items: stretch;

              &.odd-header-row-item-count {
                >*:nth-child(2n) {
                  background-color: $col-background;
                }
              }

              &:not(.odd-header-row-item-count) {
                >*:nth-child(2n + 1) {
                  background-color: $col-background;
                }
              }
            }

            .grid-cell {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0.6rem;
              min-width: 0;

              @media #{$mat-lt-sm} {
                padding: 0 1.2rem;
              }

              .label {
                color: $col-text-light;
                flex-shrink: 1;
                overflow: hidden;
                text-overflow: ellipsis;

                @media #{$mat-lt-sm} {
                  width: 72px;
                }
              }
            }
          }

          .tab-overlay {
            flex: 1;
            gap: 1.2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media #{$mat-lt-sm} {
              justify-content: center;
              padding: 1.2rem;
            }

            * {
              flex: 1;
            }

            .author {
              display: flex;
              align-items: center;
              gap: 0.6rem;
              white-space: nowrap;

              .mdi {
                height: 0;
                display: flex;
                align-items: center;
                font-size: 1.8rem;
                color: $col-text-light;
              }
            }
          }
        }
      }

      .dialog-detail {
        min-height: 0;
        height: 100%;
        $tab-header-height: 3.6rem;
        $mobile-tab-header-height: 4.8rem;

        @media #{$mat-lt-sm} {
          margin-bottom: $mobile-tab-header-height + 6rem; // tab-header + bottom buttons
        }

        .mat-tab-group {
          height: 100%;
          max-height: 100%;

          .mat-tab-header {
            align-self: flex-end;
            overflow: visible;
            height: $tab-header-height;
            margin-top: -$tab-header-height;
            border-bottom: none;
            padding-right: 16px;

            @media #{$mat-lt-sm} {
              z-index: 10;
              height: $mobile-tab-header-height;
              position: absolute;
              bottom: 6rem;
              left: 0;
              right: 0;
              margin: 0;
              padding: 0;
              align-self: stretch;
              background-color: $col-backcontrast;
            }

            // scrolling arrows left/right
            .mat-tab-header-pagination {
              display: none !important;
            }

            .mat-tab-label-container {
              overflow: visible;

              .mat-tab-label {
                border: 1px solid $col-border;
                margin: 0 0 0 -1px;
                border-bottom-color: transparent;
                padding: 0 1.2rem;
                min-width: 4.2rem;
                height: $tab-header-height;
                line-height: 4.2rem;

                @media #{$mat-lt-sm} {
                  height: $mobile-tab-header-height;
                  line-height: $mobile-tab-header-height;
                  flex: 1;
                }

                &+.mat-tab-label {
                  border-left-color: transparent;
                }

                .tab-label-ico {
                  font-size: $fontsize-icon;
                }

                &.mat-tab-label-active {
                  background-color: $col-backcontrast;
                  border-left-color: $col-border !important;
                  border-bottom-color: $col-primary !important;
                  opacity: 1;
                }
              }
            }
          }

          .mat-tab-body-wrapper {
            height: 100%;

            .mat-tab-body-content {
              .tab-content-container {
                margin: 1.2rem $scrollbar-width;

                &.details {
                  display: flex;
                  gap: 1.2rem;
                  overflow: hidden;

                  @media #{$mat-lt-sm} {
                    flex-direction: column-reverse;

                    .comments-container .comment .buttons {
                      flex-direction: column;
                    }
                  }

                  .main {
                    flex: 3;
                    min-width: 0;
                  }

                  .secondary {
                    flex: 2;
                    min-width: 0;
                  }

                  .vertical-content {
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem;
                  }
                }

                &.history {
                  .history-content-root {
                    padding: 0;

                    .history-header {
                      height: $slim-panel-height;
                    }
                  }
                }

                &.files {
                  .fixed-content-container {
                    display: flex;
                    justify-content: center;
                    background: $col-backcontrast;
                    position: sticky;
                    top: 0px;
                    padding: 0.6rem 0;
                    z-index: 50;
                  }

                  .c4-docgrid-container {

                    // box-shadow: none !important;
                    // padding: 1.8rem 0 1.2rem 0;
                    .p-datatable-resizable>.p-datatable-wrapper {
                      overflow: visible;
                    }

                    .p-datatable-thead {
                      position: sticky;
                      // top: 3.6rem + 2 * 0.6rem; // broken since prime ng upgrade
                      background: $col-backcontrast;
                      z-index: 49;
                      box-shadow: 0 1px 0 0 $col-selected;
                    }
                  }
                }
              }
            }
          }
        }

        .slim-expansion-panel {
          margin: $slim-panel-margin;
          border-radius: 0;
          box-shadow: 0 0;

          .mat-expansion-panel-header {
            border-bottom: 1px solid $col-selected;
            height: $slim-panel-height;
            padding: 0 1.2rem 0 0;
            font-size: 1em;

            .mat-expansion-panel-header-title {
              color: inherit;
              font-size: inherit;
              font-weight: 500;
              display: flex;
              align-items: center;
              gap: 0.6rem;
            }

            .mat-expansion-indicator::after {
              display: block;
            }

            h3 {
              margin: 0;
            }
          }

          .mat-expansion-panel-body {
            padding: 0 0 0.6rem 0;
          }

          .mat-expansion-panel-content {
            margin-top: 1.2rem;
            font-size: inherit;

            .image {
              width: 100%;
            }

            h4 {
              margin: 0 0 0.6rem;
              border-bottom: 1px solid $col-selected;
            }

            @media #{$mat-lt-sm} {
              .checkbox-form-root {
                grid-template-columns: 1fr;
              }
            }
          }
        }

        .expansion-panel-header-like {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $col-selected;
          height: $slim-panel-height;
          line-height: $slim-panel-height;
          font-weight: 500;
          font-size: 1em;

          .error {
            font-weight: 400;
            color: $col-warning;
            opacity: 0.8;
            white-space: nowrap;
            overflow-y: hidden;
            margin-left: 0.6rem;

            .mdi {
              margin-right: 0.6rem;
            }
          }
        }

        .tab-content-container {
          margin: 1.2rem $scrollbar-width;

          &.details {
            display: flex;
            gap: 1.2rem;
            overflow: hidden;

            @media #{$mat-lt-sm} {
              flex-direction: column-reverse;

              .comments-container .comment .buttons {
                flex-direction: column;
              }
            }

            .main {
              flex: 3;
              min-width: 0;
            }

            .secondary {
              flex: 2;
              min-width: 0;
            }

            .vertical-content {
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
            }
          }

          &.history {
            .history-content-root {
              padding: 0;

              .history-header {
                height: $slim-panel-height;
              }
            }
          }
        }
      }
    }
  }
}