@import 'core/colors';

.c4-documents-header-overlay {
  display: flex;
  gap: 0.6rem;

  .mat-button {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.15s linear 0.25s;

    &.c4-hide-multiselect-action {
      pointer-events: none;
      display: none;
      transition: opacity 0.25s linear;
    }

    &.c4-disable-multiselect-action {
      pointer-events: none;
      opacity: 0.2;
      transition: opacity 0.25s linear;
    }
  }
}

.c4-p-table-container {
  &.card {
    background: $col-backcontrast;
    border-radius: 0.4rem;
    @include default-box-shadow;
    padding: 3.4rem 0 1.2rem 0;
    position: relative;
  }
}

.c4-busy {
  .p-datatable {
    pointer-events: none;
  }

  .state-dot {
    opacity: 0;
  }
}

.c4-await-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.01;
  display: none;

  &.c4-busy {
    display: block;
  }
}

span.c4-nowrap {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c4-crumb-menu-item {
  max-width: 18rem !important;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.c4-document-browser {
  .offset-left {
    left: 1rem;
    position: relative;
  }

  td.c4-center-label {
    text-align: center !important;
  }

  tr td.c4-thumbnail {
    padding: 0;
    vertical-align: middle;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 3.6rem;
      }
    }
  }

  td.c4-center-icon {
    text-align: center !important;
  }

  th.c4-center-icon,
  th.c4-center-label {
    text-align: center !important;
  }

  .c4-file-icon {
    color: $col-primary;
    font-size: $fontsize-icon;
    // width: 1.2rem;
    // height: 1.2rem;

    &.c4_icon_Folder:before {
      content: '\f024b';
    }

    &.c4_icon_File:before {
      content: '\f0214';
    }

    &.c4_icon_DeletedItem:before {
      content: '\f0377';
    }

    &.c4-file-icon-readOnly {
      color: $col-selected !important;
    }
  }

  tr.c4-selected-row {
    background-color: $col-primary !important;

    .mat-badge-content {
      background-color: $col-primary !important;
    }

    td .c4-file-icon,
    td * {
      color: $col-backcontrast !important;
    }

    td .c4-grid-cell .c4-partial-busy-indication-content.c4-busy {
      color: rgba(0, 0, 0, 0) !important;

      * {
        color: rgba(0, 0, 0, 0) !important;
      }
    }
  }

  .c4-main-row.c4-selected-row.c4-expanded+.c4-subrow {
    td {
      background-color: $col-primary !important;
    }

    td * {
      color: $col-backcontrast !important;
    }

    td .c4-grid-cell .c4-partial-busy-indication-content.c4-busy {
      color: rgba(0, 0, 0, 0) !important;

      * {
        color: rgba(0, 0, 0, 0) !important;
      }
    }
  }

  .c4-toolbar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 0.6rem;
    background: $col-backcontrast;
    z-index: 101;
    transform: translateY(100%);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    color: $col-text-light;
    font-weight: 500;

    .mdi {
      color: $col-text-light;
      font-size: $fontsize-icon;
      padding: 0 0.6rem 0 0;

      @media #{$mat-xs} {
        padding: 0 0 0 0;
      }
    }

    &.visible {
      transform: translateY(0);
      box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
    }

    button {
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.15s linear 0.25s;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
      }

      &.c4-hide-multiselect-action {
        pointer-events: none;
        display: none;
        transition: opacity 0.25s linear;
      }

      &.c4-disable-multiselect-action {
        pointer-events: none;
        opacity: 0.2;
        transition: opacity 0.25s linear;
      }
    }
  }

  .c4-row-selector {
    position: absolute;
    transition: opacity 0.2s linear;
    opacity: 1;
    width: 4.8rem;
    line-height: 1.8rem;
    padding: 0.3rem 0.4rem;
    text-align: right;
    color: $col-border;
    overflow: hidden;
    height: 2.4rem;
    z-index: 99;
    cursor: pointer;

    &:hover {
      color: $col-text-light;
    }

    .mdi {
      font-size: $fontsize-icon;
    }
  }

  .c4-flex-end {
    margin-left: auto;
  }

  .c4-grid-edit-holder {
    display: flex;
    height: auto;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: stretch;
    padding: 0 0.6rem 0 1rem;
    background: $col-background;
    width: 100%;
    z-index: 44;
    min-width: 24rem;

    input {
      border: 0;
      background: $col-backcontrast;
      line-height: 2.4rem;
      border-bottom: 1px solid $col-primary;
      padding: 0.2rem 0.1rem 0 0.6rem;
      width: 66%;
      font-size: inherit;
      flex-grow: 2;
      height: 2.6rem;
    }

    .c4-edit-ctrl-suffix {
      line-height: 2.6rem;
      height: 2.6rem;
      padding: 0 0.2rem;
      background: $col-hover;
      color: $col-text-light !important;
      min-width: 3.6rem;
      max-width: 4.8rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;

      &:empty {
        display: none;
      }
    }

    .c4-input-ctrl {
      color: $col-text-light !important;
      min-width: 2.6rem;
      text-align: center;
      position: relative;
      height: 2.6rem;
      padding: 0;
      line-height: 2.6rem;
      cursor: pointer;
      margin-left: 0.6rem;
      border-radius: 0.4rem;

      &.hide {
        display: none;
      }

      &:hover {
        background: $col-hover;
      }

      .mdi {
        font-size: $fontsize-icon;
      }
    }
  }

  .c4-file-browser-tools {
    background: $col-backcontrast;
    margin: 2.4rem 0;
    overflow: hidden;
    color: inherit;
    height: 4.8rem;
    position: relative;
    clear: both;

    .c4-file-browser-path {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 1.8rem;
      z-index: 77;
      line-height: 1.6rem;

      .c4-browser-path-content {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        .c4-browser-root {
          color: $col-text-light;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.6rem;

          .c4-browser-rootcrumb {
            letter-spacing: 0.1rem;
          }

          .mdi {
            vertical-align: middle;
            font-size: $fontsize-icon;
          }

          &:hover .mdi:before {
            //text-decoration: underline!important;
          }
        }

        .c4-browser-crumb {
          padding-left: 2.1rem;
          letter-spacing: 0.1rem;
          display: inline-block;
          white-space: nowrap;
          max-width: 20%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .c4-browser-crumb:before {
          content: '\f0142';
          font-size: $fontsize-icon;
          text-align: center;
          display: inline-block;
          width: 2.4rem;
          color: $col-text-light !important;
          font-family: 'Material Design Icons';
          pointer-events: none;
          position: absolute;
          margin: 0 0 0 -2.4rem;
        }

        .c4-browser-crumb.c4-browser-info::before {
          content: '';
        }

        .c4-browser-crumb.c4-browser-text-info::before {
          content: '';
          display: none;
        }

        &>span.c4-browser-searchcrumb-container {
          display: inline-block;
          padding: 0 0.1rem 0 2.4rem;
          color: $col-text-light;
          white-space: nowrap;
          width: 16%;
          flex-grow: 1;
          max-width: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;

          .c4-browser-searchcrumb {
            padding-left: 0.2rem;
            font-style: italic;
          }
        }

        &>span.c4-browser-crumb.c4-browser-info {
          max-width: unset;
        }

        &>span.c4-browser-crumb.c4-browser-text-info {
          padding-left: 0.6rem;
          max-width: unset;
        }

        &>a {
          display: inline-block;
          padding: 0 0.1rem;
          color: $col-text-light;
          cursor: pointer;

          &:hover {
            //text-decoration: underline;
          }
        }

        .c4-active-crumb {
          color: $col-primary !important;
          text-decoration: none !important;
          //cursor: default !important;
          //pointer-events: none;
        }
      }
    }
  }

  .c4-tree-link {
    cursor: pointer;
    color: $col-text;

    &:hover {
      text-decoration: underline;
    }
  }

  .c4-tree-link-subline {
    cursor: pointer;
    color: $col-text-light;
    font-size: 1.1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .c4-docgrid-container {
    background: $col-backcontrast;
    border-radius: 0.4rem;
    position: relative;
    // dropcss

    &:before {
      content: ' ';
      position: absolute;
      top: 0.6rem;
      left: 0rem;
      bottom: 0.6rem;
      right: 0rem;
      border-width: 1px;
      border-style: dashed;
      border-color: transparent;
      border-radius: $border-radius-default;
      pointer-events: none;
      transition: all 0.01s linear 0.35s;
      z-index: 99;
    }

    &.app-docExDragDrop:before {
      border-color: $col-primary;
      transition: all 0.01s linear 0s;
    }

    &.app-dragDrop:before {
      border-color: $col-primary;
      transition: all 0.01s linear 0s;
    }
  }
}

.c4-docgrid-container.c4-busy,
.c4-grid-container.c4-busy {
  .c4-row-selector {
    opacity: 0.15;
  }

  td>div>* {
    background-color: $col-border;
    display: inline-block;
    opacity: 0.5;
    color: transparent !important;
    box-shadow: inset 0 -1px 0 0 $col-backcontrast;
    animation: idlerow 1s infinite linear;
    animation-direction: alternate;
    pointer-events: none;
    user-select: none;

    &:before {
      display: none !important;
    }

    * {
      color: transparent !important;
      background-color: transparent !important;
      border: none;
      pointer-events: none;
      user-select: none;

      &:before {
        display: none !important;
      }
    }
  }

  tr.c4-grid-empty-warning td>div {
    &>span {
      display: block;
    }

    &>span.c4-grid-empty-ico {
      display: none !important;
    }
  }
}

div.c4-partial-busy-indication-content.c4-busy {
  display: inline-block;
}

@keyframes idlerow {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.6;
  }
}

// common custom cell stylings
.c4-colheader {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1.35em);
  display: block;
  float: left;
}

.date-right {
  text-align: right !important;

  &>div {
    padding: 0 1.2rem 0 0;
  }
}

.c4-ctrl-td.td-visibility-control {
  padding: 0 !important;
  // background: #c00 !important;
}

.c4-name-column {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover .header {
    text-decoration: underline;
  }

  .header {
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .sub-header {
    display: block;
    line-height: 1.5rem;
    max-height: 3rem;
    color: $col-text-light;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

td.action-flex-grid,
td.planbutton {
  padding: 0 !important;

  div {
    display: flex;
    justify-content: center;
  }

  // background: #cc0 !important;
}

@media #{$mat-xs} {
  .c4-document-browser .c4-file-browser-tools .c4-file-browser-path .c4-browser-path-content .c4-browser-crumb {
    max-width: 30%;

    &:not(.crumb-overflow) {
      min-width: 20%;
    }
  }
}