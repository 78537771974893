@import 'core/variables';

.mat-expansion-panel.slim-expansion-panel {
  margin: $slim-panel-margin;
  border-radius: 0;
  box-shadow: none !important;

  .mat-expansion-panel-header {
    border-bottom: 1px solid $col-border;
    height: $slim-panel-height;
    padding: 0;
    font-size: 1em;

    .mat-expansion-panel-header-title {
      color: inherit;
      font-size: 1em;
    }

    .mat-expansion-indicator {
      &::after {
        display: block;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      width: $fontsize-icon;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}