@use 'core/theme';
@import 'core/colors';
@import 'core/variables';
@import 'core/helpers';
@import 'mixins/page';
@import '~material-design-icons/iconfont/material-icons.css';
@import '@mdi/font/scss/materialdesignicons.scss';
@import '~primeng/resources/themes/mdc-light-indigo/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import 'plugins/custom-primeng';
@import 'plugins/custom-material';
@import 'plugins/custom-bryntum';
@import 'plugins/custom-geojson';
@import '~tippy.js/dist/tippy.css';
@import 'bim';
@import 'tasklike';
@import 'entityDetailDialog';
@import 'projectConfig';
@import '~@bryntum/schedulerpro/schedulerpro.material.css';
@import 'components/expander';
@import 'components/header';

@import 'c4-busy';
@import 'docGrid';
@import 'dashboard';

html {
  margin: 0;
  overflow: hidden;
  font-size: $fontsize-root;
  /*always show v-scroller to prevent "nervous" layout https://css-tricks.com/snippets/css/force-vertical-scrollbar/*/
}

router-outlet {
  display: none;
}

html.dialog-focus {
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vw;
  height: 100%;
  width: 100vw;
  min-width: 100vw;
  min-height: 100%;
}

// #region Frill

.Frill_Badge {
  top: 2px !important;
  right: 2px !important;
}

// #endregion Frill

// #region Zoho

.zoho-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $col-border;
}

// #endregion Zoho

// #region SearchBarTop

.c4-searchbar-top-container {
  @include abs-top-right;
  min-height: 3rem;

  .c4-searchbar-top {
    border: 1px solid $col-hover;
    padding: 0 0.5rem 0 2.9rem;
    border-radius: 1.6rem;
    background: $col-backcontrast;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search-ico {
      font-size: $fontsize-icon;
      position: absolute;
      left: 0.4rem;
      opacity: 0.6;
      top: 0.1rem;
    }

    input {
      color: inherit;
      border: 0;
      background: transparent;
      width: 13.2rem;
      padding: 0;
      margin: 0;
      text-align: left;
      text-indent: 0;
      line-height: 1.6rem;
      overflow: visible;
      position: relative;
      font-size: inherit;
    }

    input::placeholder {
      color: $col-text;
      opacity: 0.4;
    }

    .show-onparent-hover {
      position: relative;
      line-height: 2.4rem;
      border-radius: 100%;
      overflow: hidden;
      height: 2.4rem;
      width: 2.4rem;
      display: inline-block;
      padding: 0;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      margin: 0.2rem;
      cursor: pointer;

      .mdi {
        font-size: $fontsize-icon;
      }

      &:hover {
        background: $col-border;
        opacity: 0.8;
      }
    }

    &:hover {
      .show-onparent-hover {
        opacity: 0.4;
        visibility: visible;
      }
    }

    @media #{$mat-xs} {
      input {
        width: 9.8rem !important;
      }
    }
  }
}

// #endregion

// #region Links

a.c4-href-like {
  color: $col-text-link;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// #endregion

// #region Build365

@mixin backgroundLogo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.logo-icon {
  @include backgroundLogo;
  background-image: url('/assets/customization/images/BUILD 365 Icon.svg');
  width: $fontsize-icon;
  height: $fontsize-icon;
}

.logo-text {
  @include backgroundLogo;
  background-image: url('/assets/customization/images/logo_text_path.svg');
  width: 100%;
  height: 100%;
}

// #endregion Build365

// #region Backgrounds

.c4-bg-dashed {
  background: repeating-linear-gradient(45deg, $col-border, $col-border 1em, $col-backcontrast 1em, $col-backcontrast 2em);
}

// #endregion Backgrounds

// #region Forms

input.b365-slim-input {
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px $col-border;
  font-size: 1.4rem;
}

// #endregion Forms

// #region Data

.c4-no-data-container {
  display: flex;
  justify-content: center;
  height: 3.6rem;
  align-items: center;
  gap: 0.6rem;
  color: $col-text-light;

  .mdi {
    font-size: $fontsize-icon;
  }
}

// #endregion Data

body[page$='modelviewer'] .c4-footer-bottom-pad {
  display: none !important;
}

body[page$='modelviewer'] c4-footer {
  display: none !important;
}

body {
  height: 100vh; // Fallback
  height: 100dvh;
  background: $col-background;
  margin: 0;
  padding: 0;
  font-size: $fontsize-desktop;
  color: $col-text;

  &[contentonly='true'] {

    app-toolbar,
    #c4MainSide,
    .c4-footer-bottom-pad,
    c4-footer {
      display: none !important;
    }

    #c4MainContent {
      min-height: 100vh !important;
      padding-top: 0 !important;
    }

    .c4-page-wrapper {
      grid-template-rows: 1fr;
    }

    .c4-page-wrapper .c4-content-wrapper {
      min-height: 100vh !important;
    }

    .page-part {
      padding-bottom: 2rem !important;
    }
  }

  .c4-login-language-switcher {
    position: fixed;
    top: 0.6rem; // calc(0.6rem + env(safe-area-inset-top));
    right: 0.6rem;
    z-index: 999;

    button {
      font-size: inherit;
      display: block;
      line-height: 3.6rem;
      height: 3.6rem;
      padding: 0 1.2rem;
      width: 100%;
      text-align: left;
      font-size: inherit;
      font-weight: 400;
      line-height: inherit;

      .mat-button-wrapper {
        height: inherit;
        width: inherit;
        display: flex;
        align-items: center;
      }
    }
  }

  &:not(.ismobileMenu) {
    .mobile-only {
      display: none;
    }
  }

  &.ismobileMenu {
    .c4-page-header {
      .c4-page-header-toggle {
        display: flex !important;
      }

      .c4-bread-crumbs {
        display: none;
      }
    }

    .c4-login-language-switcher {
      button {
        padding: 0 1.2rem !important;
        text-align: center !important;

        .mdi {
          padding: 0 !important;
        }

        .c4-langselect-span {
          display: none !important;
        }
      }
    }
  }

  .c4-loading-overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.c4-progress-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .mat-progress-bar {
        width: 30rem;
        max-width: 80%;
        z-index: 150;
      }
    }

    .c4-loading-overlay-container-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $col-backdrop;
      z-index: 100;
    }
  }

  .c4-bread-crumbs .mat-button {
    padding: 0.1rem 0.6rem;
    line-height: 2.4rem;
    display: flex;
    border-radius: 0.2rem;
    justify-content: center;
    align-items: center;
    min-width: 1.4rem;
  }

  input {
    background-color: $col-backcontrast;
    color: $col-text;
    border-radius: 0 !important;

    &::placeholder {
      color: $col-text-light;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  c4-root {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0; // env(safe-area-inset-top);
    bottom: 0;

    .mat-drawer-container[fullscreen] {
      position: relative;
      height: 100%;
      overflow: hidden;
    }
  }

  * {
    box-sizing: border-box;
  }

  .c4-page-wrapper {
    min-height: 100%;
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    animation: initPage 0.4s linear;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4.8rem 1fr;
    grid-gap: 0;

    .c4-content-wrapper {
      background: $col-background;
      transition: margin 0.3s linear;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-between;

      app-settings {
        height: 100%;
      }
    }
  }

  &.show-side-nav {
    .c4-page-wrapper {
      .c4-sidebar {
        transform: translateX(0) !important;
      }
    }
  }

  /*common page elements/parts */
  .page-part {
    @include page-centered();
    padding-top: 0;
    padding: 0 2.4rem 2.4rem;

    &.stretched {
      max-width: 100vw !important;
      min-height: 100%;
    }
  }

  &.sidenav-closed {
    .c4-page-header {
      width: 100% !important;
      margin-left: 0rem !important;

      .c4-page-header-toggle-inline {
        width: 6rem !important;
        opacity: 1 !important;
        margin-left: -1.8rem !important;
        //transition:  opacity .3s  linear,width .25s .3s cubic-bezier(.25,.8,.25,1)!important;
        transition: width 0.35s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.3s 0.2s linear !important;
      }
    }
  }

  .c4-page-header {
    transition: all 0.1s linear;

    &:after {
      content: ' ';
      transition: all 0.2s linear;
    }
  }
}

body[page$='rooms'],
body[page$='roomTemplates'],
body[page$='diary'] {
  .cdk-drag-preview {
    background-color: $col-backcontrast;
    box-shadow: 0 5px 5px -3px rgba(223, 181, 181, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

    * {
      display: none;
    }
  }

  app-room-book {
    height: 100%;
  }

  .room-tree-sidebar {
    .p-tree-wrapper {
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: $col-text-light;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: $col-selected;
      }
    }
  }
}

//custom trre icon buttons
body[page$='admincategories'] .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
  width: 3.6rem;
  height: 3.6rem;
}

body[page$='rooms'],
body[page$='roomTemplates'] .tasklike-dialog,
body[page$='defects'] .tasklike-dialog,
body[page$='diary'] .tasklike-dialog,
body[page$='bim'] .tasklike-dialog {

  // .mat-dialog-container {
  //     padding: 0;
  //     border-radius: 0;
  //     overflow: hidden;
  // }
  .mat-tab-header {
    padding-right: 16px;

    .mat-tab-label-container {
      overflow: visible;
    }
  }

  //forms
  .c4-dialog-wrapper,
  .integrated-edit-dialog-container {

    //select
    .select {
      display: inline-block;

      .mat-form-field-disabled {
        pointer-events: none !important;
      }

      .mat-form-field-appearance-legacy {
        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-select-arrow {
            width: 0;
            height: 0;
            border-left: 0.4em solid transparent;
            border-right: 0.4em solid transparent;
            border-top: 0.4em solid;
            margin: 0 0.2em;
          }

          .mat-form-field-infix {
            display: block;
            position: relative;
            flex: auto;
            min-width: 5em;
            width: 14em;
            border: 0;
            padding: 0 !important;

            .mat-form-field-label-wrapper {
              top: 0;
              padding-top: 0;
            }
          }

          .mat-form-field-underline {
            bottom: 0;
            opacity: 0.5;
            width: auto;
            left: 0;
            right: 1.8rem;
          }
        }
      }

      .mat-form-field-appearance-legacy:empty {
        .mat-form-field-underline {
          display: none;
        }
      }

      &.isValid {
        .mat-form-field-appearance-legacy {
          .mat-form-field-wrapper {
            .mat-form-field-underline {
              opacity: 0.5;
            }

            .mat-select-arrow-wrapper {
              opacity: 0;
            }

            &:hover {
              .mat-form-field-underline {
                opacity: 0.75;
              }

              .mat-select-arrow-wrapper {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    //tabs

    &.fullscreen .tasklike-tab-container {
      .mat-tab-label {
        &:last-child {
          border-right: 1px solid $col-border;
        }
      }
    }
  }
}

body[page$='projects'],
body[page*='projectscreate'],
body[page$='rooms'],
body[page$='bim'],
body[page$='planning'],
body[page$='planning-viewer'],
body[page$='ifc'],
body[page*='settings'],
body[page*='create'],
body[page^='admin'],
body[page$='defects'],
body[page$='diary'],
body[page$='planschema'],
body[page$='scheduler'],
body[page$='roomTemplates'],
body[page$='attributes'],
body[page$='categories'],
body[page$='workpackages'],
body[page$='workpackageTemplates'],
body[page$='workpackageSequences'],
body[page$='specialDates'],
body[page$='gallery'],
body[page*='documents'] {
  c4-footer {
    display: none;
  }

  // &.minified-nav {
  //   .c4-page-header {
  //     margin-left: 4.8rem;
  //     width: calc(100vw - 5em);
  //   }
  // }

  .c4-page-header-wrapper,
  .c4-content-footer {
    max-width: 100vw !important;
  }

  button.c4-context-menu-item.delete {
    color: $col-warning;

    .mdi {
      color: $col-warning;
    }
  }

  tr.c4-selected-row {
    background-color: $col-primary !important;

    td .c4-file-icon,
    td * {
      color: $col-primary-contrast !important;
    }

    td .c4-grid-cell .c4-partial-busy-indication-content.c4-busy {
      color: rgba(0, 0, 0, 0) !important;

      * {
        color: rgba(0, 0, 0, 0) !important;
      }
    }
  }

  .inline-auto-complete {
    min-width: 18rem !important;
    margin-left: -1.2rem;
  }

  .c4-await-overlay.upload {
    display: block;
    background: rgba(245, 245, 245, 0.55);
    opacity: 1;
  }

  .c4-tab-header-select-toolbar {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(101%);
    background: $col-backcontrast;
    z-index: 2;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    padding-left: 0.6rem;

    &.visible {
      transform: translateY(0%);
    }

    .c4-select-toolbar-content {
      display: flex;
      align-self: center;
      flex: 1 1 auto;
      justify-content: flex-start;
      padding-right: 0.6rem;

      .mdi {
        font-size: $fontsize-icon;
        padding: 0 0.6rem 0 0;
      }

      &.stc-right {
        justify-content: flex-end;
      }
    }
  }

  .p-datatable-scrollable-body {
    min-height: 19.2rem;
    max-height: calc(100vh - 30rem);
    padding-bottom: 0.3rem;
  }
}

@media #{$mat-sm} {
  html {
    font-size: $fontsize-tablet;

    body {
      .c4-page-wrapper {
        .c4-sidebar {
          transform: translateX(-101%);
        }

        .c4-content-wrapper {
          margin-left: 0;

          .c4-footer-pad {
            width: auto;
          }
        }
      }
    }
  }
}

@media #{$mat-xs} {
  html {
    overflow-y: visible;
    font-size: $fontsize-phone;
  }

  body {
    .c4-xs-hide {
      display: none;
    }

    .c4-document-browser .c4-upload-ctrl {
      button {
        padding: 0 0.6rem;
        min-width: 1.2rem;
      }

      .mdi {
        margin: 0;
      }
    }

    .c4-document-browser .c4-file-browser-path {
      padding: 0.6rem;
    }

    .c4-page-wrapper {
      .c4-sidebar {
        transform: translateX(-101%);
      }

      .c4-content-wrapper {
        margin-left: 0;

        .c4-content-footer {
          .c4-footer-pad {
            width: auto;

            .c4-footer-content {
              width: auto;
            }

            .c4-footer-links {
              max-width: 99%;
              position: relative;
              padding: 0 0 0 0;
              text-align: right;
            }
          }
        }
      }

      .c4-popup-holder {
        position: fixed;
        right: 6vw;
        width: 90vw;
      }
    }
  }
}

//admin
body[page^='admin'] {
  .c4-portal-only {
    display: none !important;
  }
}

// not admin
body:not([page^='admin']) {
  .c4-admin-only {
    display: none;
  }
}

/* responsive fontsizes, colcount, etc*/
@keyframes initPage {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.error-message {
  display: flex;
  gap: 0.5em;
  align-items: center;
  color: $col-warning;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  background: transparent !important;
}

.leaflet-touch {
  user-select: none;
}

// .leaflet-bar {
//   border: none;
// }

// .leaflet-top.leaflet-left {
//   width: auto;
//   display: grid;
//   padding: 1.2rem 0 0 1.2rem;
//   grid-template-columns: repeat(3, min-content);
//   column-gap: 1.2rem;
// }

// .leaflet-left .leaflet-control {
//   margin: 0;
// }

// .leaflet-touch .leaflet-bar {
//   display: flex;
// }

// .button-container .leaflet-pm-actions-container {
//   top: 120%;
// }

// .leaflet-bar a {
//   border-bottom: none;
// }

// .button-container .leaflet-pm-actions-container:not(.pos-right) a.leaflet-pm-action,
// .leaflet-pm-toolbar .leaflet-pm-actions-container a.leaflet-pm-action:first-child:not(.pos-right),
// .leaflet-pm-toolbar .leaflet-pm-actions-container a.leaflet-pm-action:last-child.pos-right,
// .button-container .leaflet-pm-actions-container:not(.pos-right) a.leaflet-pm-action:last-child {
//   border-radius: 0;
// }

.overlay {
  display: grid;

  >* {
    grid-area: 1/1/2/2;
  }
}

.color-white {
  color: white;
}

.hover-color-white:hover {
  color: white;
}

.color-black {
  color: black;
}

.hover-color-black:hover {
  color: black;
}

.grid-color-pill {
  display: block;
  margin: -6px 0px;
  max-width: 12rem;
}

.dialog-overflow-hidden {
  mat-dialog-container {
    overflow: hidden;
  }
}

@media print {
  .wide-dialog {
    min-height: 100% !important;
    min-width: 100% !important;
  }
}

// #region Responsiveness

@media #{$mat-lt-sm} {
  .c4-phone-hidden {
    display: none !important;
  }
}

@media #{$mat-gt-xs} {
  .c4-phone-only {
    display: none !important;
  }
}

@media #{$mat-lt-md} {
  .c4-mobile-hidden {
    display: none !important;
  }
}

@media #{$mat-gt-sm} {
  .c4-mobile-only {
    display: none !important;
  }
}

// #endregion Responsiveness

body.camera-preview-active {
  background-color: transparent;

  c4-root {
    display: none;
  }

  &>* {
    display: none;
    visibility: hidden;
  }

  &>.cdk-overlay-container {
    display: flex;
    visibility: visible;

    &>* {
      display: none;
      visibility: hidden;
    }

    &>.cdk-global-overlay-wrapper:has(.image-preview) {
      display: block;
      visibility: visible;

      mat-dialog-container {
        background-color: transparent;
      }

      .cdk-overlay-pane {
        margin: 0 auto;
      }

      .image-preview {
        background-color: transparent;
      }
    }
  }
}

.ngx-mat-colorpicker-content .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 8px;
}

.hide-cell-border {
  border-inline-end: none !important;
}

// body.scanner-active {
//   --background: transparent;
//   --ion-background-color: transparent;
// }

.leaflet-top,
.leaflet-left {
  transform: translate3d (0, 0, 0);

  will-change: transform;
}

.custom-snackbar {
  white-space: pre-line;
}