@import 'core/colors';
@import 'core/variables';

// #region Header

.b-grid-headers {
  .horizontally-devided {
    border-left: solid 1px #e0e0e0;
  }

  .vertically-devided {
    border-bottom: solid 1px #e0e0e0;
  }

  .b-grid-header-text {
    font-weight: 300;
  }

  .b-sch-header-timeaxis-cell {
    font-weight: 300;

    &.calendar-week {
      padding: 0;
      align-items: stretch;

      .b-sch-header-text {
        flex: 1;
        display: flex;
        padding: 0;

        .label-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          &.current {
            background-color: #3183fe23;
            color: #2196f3;
          }
        }
      }
    }
  }
}

// #endregion

// #region Scheduler

// align first column of header/resources
.b-grid-panel-body {

  .b-grid-headers,
  .b-grid-row {
    .indent {
      padding-left: 2.4rem;

      .b-tree-expander {
        margin-left: 0;
      }
    }
  }
}

.b-grid-subgrid {
  .b-column-line-major {
    border-color: transparent !important;
  }

  .b-sch-highlighted-range.b-unavailable {
    background-color: #00000030;
  }

  $tree-header-background-color: #fafafa;

  &.b-timeline-subgrid {
    .b-tree-parent-row.b-grid-row.b-selected:not(.b-group-row) {
      background-color: $tree-header-background-color;
    }
  }

  .b-tree-parent-row {
    z-index: 3;

    &:not(.b-selected) {
      background-color: $tree-header-background-color;
    }

    .b-grid-cell {
      border: none !important;

      &.b-tree-parent-cell {
        font-weight: 300;
        color: #000;
      }
    }
  }
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover .b-grid-cell {
  background-color: #f5f5f5;
}

// #endregion

// #region Scrollbar

// Chrome
.b-grid-panel-body {
  .b-grid-header-container {
    order: -1;
  }

  .b-virtual-scrollers {
    order: -1;

    .b-virtual-scroller {
      overflow-x: scroll !important;
    }
  }
}

// Firefox
.b-schedulerbase.b-overlay-scrollbar .b-virtual-scrollers {
  bottom: auto;
  top: 52px;
}

// #endregion Scrollbar

// #region Splitter

.b-gridbase.b-split .b-grid-splitter {
  flex: 0 0 0.6rem !important;
  z-index: 10;

  &:not(.b-disabled) .b-grid-splitter-inner {
    left: 0 !important;
    width: 0.6rem !important;

    .b-grid-splitter-buttons {

      .b-grid-splitter-button-expand,
      .b-grid-splitter-button-collapse {
        &:hover .b-grid-splitter-button-icon {
          fill: $col-accent;
        }
      }
    }
  }
}

// #endregion Splitter

// #region Events

.b-grid-subgrid .b-sch-foreground-canvas .b-sch-event-wrap .b-sch-event {
  &:hover {
    .hover-color-white {
      color: white;
    }

    .hover-color-black {
      color: black;
    }
  }

  &.b-sch-event-selected {
    .selected-color-white {
      color: white;
    }

    .selected-color-black {
      color: black;
    }
  }

  .b-sch-event-content {
    flex: 1;

    .workpackage-label-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mdi {
        font-size: 1.8rem;
      }
    }
  }
}

// #endregion Events

// #region TimeRanges

.b-grid-header .b-sch-timerange.b-sch-line {
  padding: 0;
}

@mixin timeRangeTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $col-primary-contrast;
  padding: 0 0.6rem;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.b-grid-subgrid {
  .b-sch-foreground-canvas {
    z-index: 5;

    .b-sch-highlight-container {
      .calendar-week {
        border-color: #3183fe63;
      }

      .border-only {
        background-color: transparent;
        border-style: solid;
      }

      .body-only {
        border: none;
      }
    }
  }

  .b-timeranges-canvas {
    display: initial; // pdf export fix

    .b-sch-timerange {
      pointer-events: all; // enable tooltips
      cursor: default;
      z-index: inherit !important;

      &.b-sch-range .time-range-body {
        @include timeRangeTitleContainer;
        margin-top: 30px;
      }

      &.b-sch-line .time-range-body {
        @include timeRangeTitleContainer;
      }
    }
  }
}

.drag-mode {
  .b-grid-subgrid .b-timeranges-canvas .b-sch-timerange {
    pointer-events: none;
  }
}

// #endregion

// #region Drag'n'Drop

.drag-item-for-scheduler {
  // display: flex;
  // align-items: center;
  // gap: 1.2rem;
  // cursor: pointer;

  &.b-dragging {
    z-index: 1001;

    // styles to apply during dragging
    .workpackage-sequence.with-title {
      border: none;
    }

    .sequence-title {
      display: none;
    }

    &:not(.b-aborting) {
      opacity: 1;

      &.b-drag-invalid {
        opacity: 0.4;
      }
    }
  }
}

// #endregion

// #region Dependencies

.b-sch-dependency {
  stroke-width: 2;
  stroke: $col-primary;
}

.b-sch-dependency-arrow {
  fill: $col-primary;
}

// #endregion

// #region Footer / Summary

.b-grid-footer {
  &[data-column='floor'] {
    overflow: visible;
  }

  &.b-sch-summarybar {
    .b-timeaxis-tick {
      position: relative;
      padding: 0;
      height: 4.8rem;

      .histogram-bar {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #bbf7d0;

        &.total {
          background-color: #dcfce7;
        }

        &.done {
          background-color: #bbf7d0;
        }
      }

      .label {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        color: #166534;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }
}

// #endregion Footer / Summary