@use 'angular-material-css-vars-legacy/public-util' as mat-css-utilities;
// @use 'sass:map';
// @use '@angular/material' as mat;
// @import '@angular/material/theming';

// $custom-typography: mat.define-legacy-typography-config($font-family: var(--font));
// // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
// //  The following line adds:
// //    1. Default typography styles for all components
// //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
// //  If you specify typography styles for the components you use elsewhere, you should delete this line.
// //  If you don't need the default component typographies but still want the hierarchy styles,
// //  you can delete this line and instead use:
// //    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();

// $c4-app-primary: mat.define-palette($myprime);
// $c4-app-accent: mat.define-palette($mysecondary);
// $c4-app-warn: mat.define-palette(mat.$orange-palette);
// $theme: mat.define-light-theme($c4-app-primary, $c4-app-accent, $c4-app-warn);
// @include mat.all-legacy-component-themes($theme);


// -----

@import 'core/variables';
@import 'core/colors';
@import 'mixins/shadow';

// $custom-typography: mat.define-legacy-typography-config($font-family: $font-family-variable,
//   );
// // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
// //  The following line adds:
// //    1. Default typography styles for all components
// //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
// //  If you specify typography styles for the components you use elsewhere, you should delete this line.
// //  If you don't need the default component typographies but still want the hierarchy styles,
// //  you can delete this line and instead use:
// //    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();

// $c4-app-primary: mat.define-palette($myprime);
// $c4-app-accent: mat.define-palette($mysecondary);
// $c4-app-warn: mat.define-palette(mat.$red-palette);
// $theme: mat.define-light-theme($c4-app-primary, $c4-app-accent, $c4-app-warn);
// @include mat.all-legacy-component-themes($theme);

// // $theme-text-light: mat.get-color-from-palette($c4-app-primary, foreground-text);
// // $theme-text-light: mat.get-color-from-palette($c4-app-primary, foreground);
// // $foreground-palette: mat.map-get($c4-app-primary, foreground);
// // $theme-text-light: mat.get-color-from-palette($foreground-palette, contrast);

// $color-config: mat.get-color-config($theme);
// $foreground-palette: map.get($color-config, 'primary');
// $col-text-light: mat.get-color-from-palette($foreground-palette, lighter);

// #region Sidebar

.c4-sidenav-list {
  padding: 0 !important;
  margin: 0;
  list-style: none;
  display: flex !important;
  flex-direction: column;

  .c4-sidenav-list-item.mat-list-item {
    margin: 0;
    overflow: visible;
    height: auto !important;
    border-radius: 0;
    display: block;
    padding: 0;
    font-weight: 400;

    &.active {
      font-weight: 500;

      .mat-list-item-content {
        padding-left: 0;
        border-left: 0.3rem solid $col-primary;
        background-color: $col-selected;
      }
    }

    .mat-list-item-content {
      display: flex;
      box-sizing: border-box;
      padding: 0;
      height: auto;
      padding-left: 0.3rem;

      .mat-ripple {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
      }

      .c4-sidenav-link {
        flex: 1;
        display: flex;
        line-height: 2.4rem;
        background: transparent;
        color: inherit;
        padding: 0 0.7rem 0 0.7rem;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        height: 4.8rem;
        align-items: center;

        .mdi {
          color: $col-text-light;
          vertical-align: middle;
          font-size: $fontsize-icon;
        }

        img {
          object-fit: contain;
          font-size: $fontsize-icon;
          width: 1.2rem;
          vertical-align: middle;
          margin-right: $off-05;
          display: inline-block;
          line-height: inherit;
        }

        span {
          line-height: inherit;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;

          &.nav-label {
            margin-left: 1.2rem;
          }
        }
      }
    }
  }
}

.cdk-overlay-container .popup-container .c4-sidenav-list.mat-list-base {
  padding: 0;

  .grouped-nav-panel {
    .grouped-nav-header {
      font-weight: 500;
      padding: 0.8rem 1.2rem;
    }

    .c4-sidenav-list-item.mat-list-item .c4-sidenav-link {
      padding-right: 2.4rem;
      height: 4rem;
      line-height: 2rem;

      .mdi {
        font-size: 2rem;
      }
    }
  }
}

body:not([page='projects']).minified-nav {
  &:not(.ismobileMenu) {
    .minifiable-container {
      .hide-on-minified {
        display: none !important;
      }
    }
  }

  .c4-page-header {
    .logowrapper {
      width: 4.8rem !important;
    }
  }

  &.ismobileMenu .c4-page-header {
    .logowrapper {
      width: auto !important;
    }
  }
}

// #endregion

// #region Mat Menu

.mat-menu-panel {
  min-height: 4.8rem !important;

  .mat-menu-content {
    padding: 0 !important;

    .mat-menu-item {
      padding: 0 $off-12 !important;
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }

    .grouped-menu-item-label {
      padding-right: 1.2rem;
    }
  }

  &.selection-menu {
    min-height: 0;

    .mat-menu-content {
      max-height: 30rem;
    }
  }
}

// #endregion

// #region Mat Buttons

.mat-icon-button,
.mat-form-field-suffix,
.mat-button,
.mat-flat-button,
.mat-stroked-button,
.mat-menu-item {
  // .mdi { //commented out and asked about necessity(pinek)
  color: $col-text-light;
  // }
}

.mat-icon-button,
.mat-form-field-suffix,
.mat-button,
.mat-flat-button,
.mat-stroked-button,
.mat-menu-item,
.mat-mini-fab {
  .mdi {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $fontsize-icon;
    font-size: $fontsize-icon;
  }
}

.mat-button,
.mat-flat-button,
.mat-stroked-button {
  min-width: 6rem !important;
  border-radius: $border-radius-default !important;
  padding: 0 1.2rem !important;

  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.6rem;
    gap: 0.6rem;
  }
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  .mat-datepicker-toggle .mat-mdc-icon-button {
    height: $fontsize-icon !important;
    width: $fontsize-icon !important;
    color: $col-text-light;

    .mat-datepicker-toggle-default-icon {
      width: $fontsize-icon;
    }
  }
}


// #endregion

// #region Mat Button Toggle Group

.mat-button-toggle-group {
  .mat-button-toggle {
    display: flex;

    .mat-button-toggle-label-content {
      line-height: 1.4rem;
    }
  }
}

// #endregion

// #region Mat Chips

input.mat-chip-input {
  width: 10rem !important;
  flex: 1 0 10rem !important;
}

.mat-chip-list.centered-items {
  .mat-chip-list-wrapper {
    justify-content: center;
  }
}

.mat-chip-list.single-column-centered {
  .mat-chip-list-wrapper {
    flex-direction: column;

    input {
      flex: 1;
    }
  }
}

.mat-chip-list-wrapper {
  margin: 0 !important;
  min-height: 3.6rem;

  .mat-standard-chip,
  input.mat-input-element {
    margin: 0.2rem;
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    .chip-label {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
  }

  .mat-standard-chip {
    border-radius: 2em;
    min-height: 2em;
    height: 2em;
    margin: 0.4rem !important;
  }

  .mat-chip {
    font-size: inherit;
  }
}

// #endregion Mat Chips

// #region Mat Select

.mat-option.detailed-option {
  .option-title {
    display: block;
    position: relative;
    transform: translateY(-10%);
  }

  .option-subtitle {
    display: block;
    position: absolute;
    font-style: italic;
    color: $col-text-light;
    font-size: 0.8em;
    transform: translateY(-80%);
  }
}

.mat-form-field.mat-form-field-type-mat-select.slim {
  &.mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      display: none;
    }
  }

  &.mat-form-field-appearance-standard {
    .mat-form-field-flex {
      padding: 0;
    }
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;

    .mat-form-field-flex {
      margin: 0;

      .mat-form-field-outline {
        top: 0;

        .mat-form-field-outline-gap {
          width: 0 !important;
        }
      }

      .mat-form-field-infix {
        padding: 0.8rem 0;
        border: none;

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            margin-top: -0.5em;
          }
        }

        .mat-select-arrow-wrapper {
          transform: none;
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-option {
  &.custom {
    line-height: unset !important;
    height: unset !important;

    &.single-option {
      padding: 0;
    }
  }
}

.single-option.custom {
  .c4-select-option {
    padding: 0 1.6rem;
  }
}

.c4-select-option {
  height: 4.2rem;
  line-height: 4.2rem;

  &.with-icon {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    &.icon-right {
      justify-content: space-between;
    }

    .mdi {
      font-size: $fontsize-icon;
    }
  }
}

app-search-select.minimalist,
app-date-range.minimalist {
  .mat-form-field {
    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-infix {
        border: none;
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

// #endregion Mat Select

// #region Mat Checkbox

.mat-checkbox.slim {
  .mat-checkbox-layout {
    vertical-align: inherit;

    .mat-checkbox-label {
      line-height: 17px;
    }
  }
}

// #endregion Mat Checkbox

// #region Mat Form Field Input

.mat-form-field {
  .mat-form-field-flex {
    align-items: center;
  }

  .mat-form-field-suffix {
    >span {
      border-top: 0.84375em solid transparent;
    }
  }
}

.mat-form-field.with-suffix {
  .mat-form-field-flex {
    input {
      text-align: right;
    }

    .mat-form-field-suffix {
      color: $col-text-light;
      width: 3.6rem;
    }
  }
}

// #endregion

// #region Mat Cards

.c4-connected-cards-container {
  border-radius: 0.4rem;
  overflow: hidden;
  @include default-box-shadow;

  >*:not(:first-child) {

    &.mat-card,
    .mat-card {
      border-top: solid 1px $col-selected;
    }
  }

  .mat-card {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}

// #endregion

// #region Mat Snackbar

.mat-simple-snackbar {
  .mat-button {
    color: inherit;
  }
}

// #endregion

// #region Mat Slide Toggle

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: $col-backdrop;

    input.cdk-visually-hidden {
      visibility: hidden; // firefox bug with overlay header
    }
  }

  &.mat-primary {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: mat-css-utilities.mat-css-color-primary(200)
      }
    }
  }

  &.mat-accent {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: mat-css-utilities.mat-css-color-accent(200);
      }
    }
  }

  &.mat-warn {
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: mat-css-utilities.mat-css-color-warn(200);
      }
    }
  }
}

// #endregion Mat Slide Toggle

// #region Mat Progress Bar

.mat-progress-bar.on-backdrop {
  .mat-progress-bar-buffer {
    background-color: $col-background;
  }
}

// #endregion Mat Progress Bar

// #region Mat Replica

.c4-mat-dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
}

// #endregion

// #region Mat Dialog

.cdk-overlay-pane.c4-dialog-stretch {
  width: 100%;
}

// #endregion Mat Dialog

.c4-mobile-full-height {
  height: 100vh;

  .mat-dialog-container {
    padding: 0;
  }
}

$c4-disabled-underline-background-image: linear-gradient(to right,
    rgba(var(--text-color), 0.42) 0%,
    rgba(var(--text-color), 0.42) 33%,
    transparent 0%);

.mat-form-field-disabled {
  .mat-form-field-underline {
    background-image: $c4-disabled-underline-background-image !important;
  }

  .mat-select-value {
    color: rgba(var(--text-color), 0.38) !important;
  }
}

.c4-disabled-formfield-underline {
  background-image: $c4-disabled-underline-background-image;
  background-size: 4px 100%;
  background-repeat: repeat-x;
  height: 1px;
  transform: scale3d(1, 1, 1);
}

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

input:focus,
*:focus {
  outline: none !important;
}

a,
a:focus,
th:focus {
  outline: 0;
  -moz-outline-style: none;
  outline-style: none;
}

.mat-drawer {
  overflow: visible !important;
}

.c4-dialog-full-width {
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

body.ismobileMenu {
  .c4-page-header {
    .logowrapper {
      width: auto !important;

      .logo-full {
        flex-grow: 0;
        max-width: 4.8rem;
        min-width: 4.8rem;
        width: 4.8rem;

        .logo-base.logo-text {
          display: none !important;
        }
      }
    }
  }
}

.mat-list-base {
  padding: 0;

  .mat-list-item {
    font-size: inherit;
  }
}

body .c4-page-wrapper {
  .mat-toolbar {
    font-size: inherit;
  }

  mat-sidenav-content.c4-sidenav-page-content {
    margin-top: 0;
    min-height: 0;
    height: 100%;
    padding-top: 0;
    overflow: hidden;
    position: relative;
  }

  .mat-drawer-inner-container {
    width: auto;
    height: 100%;
    overflow: visible;
  }

  .mat-button-ripple-round {
    border-radius: 50% !important;
  }

  .mat-toolbar .mat-icon-button {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-button-ripple-round {
      border-radius: 0 !important;
    }
  }

  .mat-card {
    padding: $off-12;
    border-radius: $border-radius-default;
  }

  .mat-card:not([class*='mat-elevation-z']) {
    @include default-box-shadow;
  }
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-size: inherit !important;
}

.c4-no-focus {
  .mat-button-focus-overlay {
    display: none !important;
  }
}

/*validation*/
.alert.alert-danger {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(110%);
  font-size: 1rem;
  color: $col-warning;
}

hr {
  color: $col-selected;
  background: transparent;
  border: 0;
  border-top: 1px solid $col-selected;
}

/* dialog components & modal */
.mat-dialog-container {
  display: block;
  padding: 1.8rem;
  border-radius: $border-radius-default;
  position: relative;
}

.c4-dialog-wrapper {
  width: 30em;
  max-width: 80vw;

  &.medium-width {
    width: 70vw;
    max-width: 72rem;

    @media #{$mat-xs} {
      width: calc(100vw - 5em);
      max-width: 99vw;
    }
  }

  &.large-width {
    width: 93.6rem;
  }

  .mat-dialog-title {
    margin: 0 0 1rem;
    display: block;
  }

  .mat-dialog-content {
    margin: 0 -1.2rem;
    padding: 0 1.2rem;
    line-height: 2.1rem;
    position: relative;
  }

  .mat-dialog-actions {
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    min-height: auto;
    align-items: center;
    margin: 1.2rem 0 0;
    flex-direction: row;
    justify-content: flex-end;
    gap: $button-gap;

    .mat-button-base+.mat-button-base {
      margin: 0;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: $col-border;
}

.mat-form-field.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    background-color: $col-border;
  }
}

.mat-tab-label {
  font-weight: 400;

  &.slim {
    min-width: 0;
  }
}

.mat-button {
  font-weight: 400;
}

.mat-tab-link,
.mat-drawer-container,
.mat-cell,
.mat-footer-cell,
.mat-step-header .mat-step-label.mat-step-label-active,
.mat-select-value,
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  font-size: 1em;
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger,
.mat-select-arrow,
.mat-header-cell,
.mat-calendar-body-label,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-calendar-arrow {
  color: $col-text-light;
}

.mat-calendar-arrow {
  border-top-color: $col-text-light;
}

.mat-radio-outer-circle,
.mat-checkbox-frame {
  border-color: $col-text-light;
}

.mat-checkbox-inner-container {
  height: 1.15em !important;
  width: 1.15em !important;
}

.mat-stepper-header::before,
.mat-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: $col-selected;
}

/*tooltip*/
.mat-tooltip {
  border-radius: $border-radius-default !important;
  margin: 0.4rem !important;
  max-width: 30rem !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: inherit !important;

  &.multi-line {
    white-space: pre-line;
    line-height: 1.4rem !important;
    padding: 0.7rem !important;
  }
}

/* custom material popups*/
.cdk-overlay-pane.full-content {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.cdk-overlay-pane.fullscreen {
  max-width: 100vw !important;
  width: 100vw;
  height: 100%;
}

.cdk-overlay-pane.tm-file-preview-dialog-panel {
  height: 100% !important;
}

.cdk-overlay-pane.team-cfg-dlg {
  width: 70em;
  max-width: 100vw !important;
  height: 96vh;
  min-width: 42rem;
  min-height: 24rem;

  .c4-dialog-wrapper .mat-dialog-content {
    display: block;
    max-height: calc(90vh - 7.5em);
    height: calc(90vh - 7.5em);
    min-height: 18rem;
    margin: 0 0 2.4rem 0 !important;
    padding: 0 2.4rem 0 0 !important;
  }

  .teams-cfg-select .mat-form-field-infix {
    min-width: 200px;
    width: 24rem;
  }
}

.cdk-overlay-pane.log-dialog {
  max-width: 100vw !important;
}

.cdk-overlay-pane {
  .mat-menu-panel {
    max-width: 25em;
    border-radius: $border-radius-default;
  }

  .drop-icon-pad {
    padding-right: 2.2em !important;
  }
}

.c4-popup-holder {
  background: $col-backcontrast;
  position: relative;
  overflow: hidden;
  padding: 0;
  line-height: 1.6rem;

  .c4-popup-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: $off-10 $off-08;

      &:nth-child(even) {
        background: $col-selected;
      }
    }
  }
}

.c4-user-ctrl.c4-popup-holder {
  .c4-popup-list {
    width: 100%;

    li {
      width: 100%;
      padding: $off-10 $off-08;
      border-bottom: 1px solid $col-selected;

      &.c4-small-item {
        padding: 0;
        border: 0 none;

        &.c4-group-start {
          margin-top: 1.2rem;
        }
      }

      &:nth-child(even) {
        background: transparent;
      }

      &:first-child {
        border-bottom: 1px solid $col-border;
      }

      .c4-menu-button.mat-menu-item {
        font-size: inherit;
        display: flex;
        border-radius: 0;
        width: 100%;
        text-align: left;
        font-size: inherit;
        font-weight: 400;
        line-height: inherit;
        align-items: center;
      }

      .c4-sender-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .c4-sender-name {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .c4-label-value-row {
    font-weight: 400;
    font-size: $fontsize-small;

    span {
      white-space: nowrap;
    }

    .c4-label {
      padding-right: 0.6rem;
    }
  }
}

// tab header material includer ( ie. switch)

.c4-header-menu {
  .c4-tab-switch {
    min-width: 6rem;
    color: $col-text-light;
    padding: 0 $off-12;
    display: block;

    @media #{$mat-sm} {
      max-width: calc(100% - 4.8rem);
      padding: 0 0.8rem;
      display: inline-block;
      height: 3.6rem;
      line-height: 3.6rem;
    }
  }
}

// themeing color
.c4-list-item .mat-menu-item-submenu-trigger::after {
  border-color: transparent transparent transparent $col-text-light;
}

/*custom formatet teaser list*/

.c4-textoverflow>span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.c4-no-bottom-margin {
  margin-bottom: 0;
}

.backdrop.full-screen {
  position: fixed !important;
  z-index: 19999;
}

/*responsive-fixes*/
@media #{$mat-sm} {
  .mat-tooltip-panel {
    background-color: transparent !important;

    .mat-tooltip {
      max-width: 36rem !important;
    }
  }
}

@media #{$mat-lt-md} {

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: inherit !important;
  }

  .cdk-overlay-pane {
    max-height: 100% !important;
    max-width: 100vw !important;
    border-radius: 0 !important;

    &.team-cfg-dlg {
      .mat-dialog-container {
        padding: 2rem 1rem !important;
      }
    }

    &.mat-tooltip-panel {
      background-color: transparent !important;

      .mat-tooltip {
        max-width: 22.8rem !important;
      }
    }

    .mat-button,
    .mat-raised-button,
    .mat-icon-button,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-fab,
    .mat-mini-fab {
      font-size: inherit !important;
    }

    &:not(.shrink) {
      .mat-dialog-container {
        min-width: 100vw;
      }
    }

    .mat-dialog-container {
      height: auto !important;

      .c4-dialog-wrapper {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        .mat-dialog-content {
          max-height: inherit !important;

          .mat-dialog-actions {
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            min-height: auto;
            align-items: center;
            margin: 1.2rem 0 0;
            flex-direction: row;
            justify-content: flex-end;
            gap: $button-gap;

            .mat-button-base+.mat-button-base {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .cdk-overlay-backdrop.c4-center-popup+* .cdk-overlay-pane {
    margin-right: -2.4rem;
  }
}

.mat-tab-group {
  &.no-content {
    .mat-tab-header {
      border-bottom: 0px solid transparent;
    }

    .mat-tab-body-wrapper {
      display: none;
    }
  }
}

//color picker
.ngx-mat-color-toggle {
  .mat-icon-button {
    position: absolute;
    margin: -3rem 0 0 -3.6rem;

    .mat-icon {
      color: col-primary !important;
      font-size: 2.4rem !important;
      width: 2.4rem !important;
    }
  }
}

.mat-form-field-prefix .ngx-mat-color-toggle .mat-icon-button,
.mat-form-field-suffix .ngx-mat-color-toggle .mat-icon-button {
  height: 4.2rem;
  width: 4.2rem;
}

.mat-placeholder-required {
  color: inherit !important;
}

.mat-selection-list {
  .mat-list-item.mat-list-option {
    font-size: 1em;

    .mat-list-item-content {
      padding: 0 1.2rem;
    }
  }
}

.mat-datepicker-content .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar-body-in-range>.mat-calendar-body-cell-content {
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}