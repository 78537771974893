@import 'core/variables';

@mixin page-centered {
  max-width: 78em;
  width: 100%;
  margin: 0 auto;
  padding-left: $pageContentPadding;
  padding-right: $pageContentPadding;
  @media #{$mat-xs} {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@mixin header-footer-centered {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-left: $pageContentPadding;
  padding-right: $pageContentPadding;
}

@mixin abs-top-right {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  transform: translate(0, -50%);

  @media #{$mat-xs} {
    right: 1.2rem;
  }
}
