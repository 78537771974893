@import 'core/colors';
@import 'core/variables';

app-geojson-editor {

  .geojson-marker-icon .marker-icon,
  .geojson-marker-icon .marker-icon:focus {
    fill: none;
    border: none;
    border-radius: 0;
    width: auto !important;
    height: auto !important;
    margin: 0px 0 0 0px !important;
    background: none;
    filter: drop-shadow(0px 0px 4px black);
  }

  .pm-text-marker textarea {
    color: var(--pm-foreground-color);
    padding: 0.3rem;
    // outline: 2px solid rgba(var(--pm-text-color), 1);
    background-color: var(--pm-background-color);
  }

  &.zero-leaflet-left {
    .leaflet-left .leaflet-control {
      margin-left: 0px;
    }
  }

  .zoom-indicator {
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 3.2rem + 0.6rem;

    display: flex;
    align-items: center;

    background-color: $col-backcontrast;
    padding: 0.4rem;
    border-radius: 0.2rem;
    font-size: 1.4rem;
  }

  // .leaflet-marker-icon {
  //   .marker-icon {
  //     fill: none;
  //     stroke: $col-primary;
  //     filter: drop-shadow(0px 0px 4px black);
  //   }
  // }

  .leaflet-pm-toolbar .leaflet-cst-icon-freedraw {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICA8Zz4KICAgICAgICA8cGF0aCBkPSJNMy42MzIsMTEuMjJDNC41NDQsNS45OTkgOS4wOTEsNC40ODUgMTIsOS4wN0MxNC4yNiwxMi42MzIgNy4yMjUsMTguODc0IDEyLDIwQzE2LjM5OCwyMS4wMzcgMTkuOTgsMTAuMTc3IDE4LjkzNiw2LjY5OSIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6cmdiKDkxLDkxLDkxKTtzdHJva2Utd2lkdGg6MnB4OyIvPgogICAgPC9nPgo8L3N2Zz4K);
  }

  .leaflet-pm-toolbar .leaflet-cst-icon-marker {
    // background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDY0IDY0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPgogICAgPHJlY3QgaWQ9Ik1hcmtlciIgeD0iMCIgeT0iMCIgd2lkdGg9IjY0IiBoZWlnaHQ9IjY0IiBzdHlsZT0iZmlsbDpub25lOyIvPgogICAgPGcgaWQ9Ik1hcmtlcjEiIHNlcmlmOmlkPSJNYXJrZXIiPgogICAgICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDEuMjUzODEsMCwwLDEuMjUzODEsLTguMTIyMDUsLTQuNzM4MTQpIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMyLjI0NCw1LjAyMUM0MC44MTUsNS4wMjEgNDcuODAyLDExLjg5NiA0Ny45NDEsMjAuNDY2TDQ3Ljk1NywyMS40MDJDNDcuOTk5LDIzLjk3NyA0Ny40MiwyNi41MjMgNDYuMjcsMjguODI3TDM0LjcwNCw1MS45OTdDMzQuMTk3LDUzLjAxNCAzMy4xMzUsNTMuNjM0IDMyLDUzLjU3N0MzMC44NjUsNTMuNjM0IDI5LjgwMyw1My4wMTQgMjkuMjk2LDUxLjk5N0wxNy43MywyOC44MjdDMTYuNTgsMjYuNTIzIDE2LjAwMSwyMy45NzcgMTYuMDQzLDIxLjQwMkwxNi4wNTksMjAuNDY2QzE2LjE5OCwxMS44OTYgMjMuMTg1LDUuMDIxIDMxLjc1Niw1LjAyMUwzMi4yNDQsNS4wMjFaTTMyLDEzLjk0NEMyNy43NjIsMTMuOTQ0IDI0LjMyMSwxNy4zODUgMjQuMzIxLDIxLjYyM0MyNC4zMjEsMjUuODYgMjcuNzYyLDI5LjMwMSAzMiwyOS4zMDFDMzYuMjM4LDI5LjMwMSAzOS42NzksMjUuODYgMzkuNjc5LDIxLjYyM0MzOS42NzksMTcuMzg1IDM2LjIzOCwxMy45NDQgMzIsMTMuOTQ0WiIgc3R5bGU9ImZpbGw6cmdiKDEwMSwxMDEsMTAxKTsiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=);
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      color: #636363;
      font-family: 'Material Design Icons';
      font-size: $fontsize-icon;
      content: '\F034E';
    }
  }

  .leaflet-pm-toolbar .leaflet-cst-icon-snapshot {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      color: #636363;
      font-family: 'Material Design Icons';
      font-size: $fontsize-icon;
      content: '\F18F4';
    }
  }

  .leaflet-pm-toolbar .leaflet-cst-icon-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      color: #636363;
      font-family: 'Material Design Icons';
      font-size: $fontsize-icon;
      content: '\F005C';
    }
  }

  .leaflet-pm-toolbar .leaflet-cst-icon-eraser {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTksM1Y0SDRWNkg1VjE5QTIsMiAwIDAsMCA3LDIxSDE3QTIsMiAwIDAsMCAxOSwxOVY2SDIwVjRIMTVWM0g5TTcsNkgxN1YxOUg3VjZNOSw4VjE3SDExVjhIOU0xMyw4VjE3SDE1VjhIMTNaIiAvPjwvc3ZnPg==);
  }

  .leaflet-pm-toolbar .leaflet-cst-icon-colorpicker {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE3LjUsMTJBMS41LDEuNSAwIDAsMSAxNiwxMC41QTEuNSwxLjUgMCAwLDEgMTcuNSw5QTEuNSwxLjUgMCAwLDEgMTksMTAuNUExLjUsMS41IDAgMCwxIDE3LjUsMTJNMTQuNSw4QTEuNSwxLjUgMCAwLDEgMTMsNi41QTEuNSwxLjUgMCAwLDEgMTQuNSw1QTEuNSwxLjUgMCAwLDEgMTYsNi41QTEuNSwxLjUgMCAwLDEgMTQuNSw4TTkuNSw4QTEuNSwxLjUgMCAwLDEgOCw2LjVBMS41LDEuNSAwIDAsMSA5LjUsNUExLjUsMS41IDAgMCwxIDExLDYuNUExLjUsMS41IDAgMCwxIDkuNSw4TTYuNSwxMkExLjUsMS41IDAgMCwxIDUsMTAuNUExLjUsMS41IDAgMCwxIDYuNSw5QTEuNSwxLjUgMCAwLDEgOCwxMC41QTEuNSwxLjUgMCAwLDEgNi41LDEyTTEyLDNBOSw5IDAgMCwwIDMsMTJBOSw5IDAgMCwwIDEyLDIxQTEuNSwxLjUgMCAwLDAgMTMuNSwxOS41QzEzLjUsMTkuMTEgMTMuMzUsMTguNzYgMTMuMTEsMTguNUMxMi44OCwxOC4yMyAxMi43MywxNy44OCAxMi43MywxNy41QTEuNSwxLjUgMCAwLDEgMTQuMjMsMTZIMTZBNSw1IDAgMCwwIDIxLDExQzIxLDYuNTggMTYuOTcsMyAxMiwzWiIgLz48L3N2Zz4=);
  }

  .leaflet-popup-content {
    font-size: 1.4rem;
  }
}