@mixin busycontent {
  background-image: none !important;
  background-color: $col-border !important;
  display: inline-block;
  opacity: 0.5;
  color: transparent !important;
  animation: idlerow 1s infinite linear;
  box-shadow: inset 0 -1px 0 0 $col-backcontrast;
  animation-direction: alternate;
  pointer-events: none;
  user-select: none;

  &:before {
    display: none !important;
  }

  * {
    color: transparent !important;
    pointer-events: none;
    user-select: none;

    &:before {
      display: none !important;
    }
  }

  div,
  span {
    color: rgba(0, 0, 0, 0) !important;
  }

  &.mat-form-field {
    >* {
      visibility: hidden;
    }
  }

  &.mat-checkbox {
    line-height: 0;

    .mat-checkbox-frame {
      display: none !important;
    }
  }
}

.c4-busy {
  .c4-busy-content {
    @include busycontent();
  }

  .c4-busy-hidden {
    display: none !important;
  }
}

.c4-partial-busy-indication-content.c4-busy {
  .c4-partial-busy-content {
    @include busycontent();
  }
}