/* general */
$border-radius-default: 0.4rem;
$scrollbar-width: 16px;

/* layout */
$width-xs-max: 599px;
$width-sm-min: 600px;
$width-sm-max: 959px;
$width-md-min: 960px;
$width-md-max: 1279px;
$width-lg-min: 1280px;
$width-lg-max: 1919px;
$width-xl-min: 1920px;

/* dialog */
$button-gap: 1.2rem;

/* font size (base) */
$fontsize-root: 10px;
$fontsize-tablet: 1.24vw;
$fontsize-phone: 2.07vw;
$fontsize-desktop: 1.4rem;

/* font sizes */
$fontsize-xx-large: 2.1rem;
$fontsize-x-large: 1.8rem;
$fontsize-large: 1.6rem;
$fontsize-small: 1.1rem;

$fontsize-icon: 2.4rem;
// deprecated
$fontsize_old_icon: 1.714285em;

//panel vars
$slim-panel-height: 3.6rem;
$slim-panel-margin: 0 0 1em 0;
/* responsiveness */
$mat-xs: 'screen and (max-width: #{$width-xs-max})';
$mat-sm: 'screen and (min-width: #{$width-sm-min}) and (max-width: #{$width-sm-max})';
$mat-md: 'screen and (min-width: #{$width-md-min}) and (max-width: #{$width-md-max})';
$mat-lg: 'screen and (min-width: #{$width-lg-min}) and (max-width: #{$width-lg-max})';
$mat-xl: 'screen and (min-width: #{$width-xl-min})';
$mat-lt-sm: 'screen and (max-width: #{$width-xs-max})';
$mat-lt-md: 'screen and (max-width: #{$width-sm-max})';
$mat-lt-lg: 'screen and (max-width: #{$width-md-max})';
$mat-lt-xl: 'screen and (max-width: #{$width-lg-max})';
$mat-gt-xs: 'screen and (min-width: #{$width-sm-min})';
$mat-gt-sm: 'screen and (min-width: #{$width-md-min})';
$mat-gt-md: 'screen and (min-width: #{$width-lg-min})';

$min-input-width: 22em;
$off-01: 0.1rem;
$off-02: 0.35rem;
$off-05: 0.7rem;
$off-08: 1rem;
$off-10: 1.4rem;
$off-12: 1.8rem;
$off-15: 2.1rem;
$off-17: 2.4rem;
$off-20: 2.8rem;
$off-25: 3.5rem;
$off-30: 4.2rem;
$off-40: 5.2rem;
$off-top: 3.2rem;
$size-01: 0.1rem;
$size-02: 0.35rem;
$size-03: 0.5rem;

/*columns & layout*/
$gridoffset: 1.5;
$gridoffsetd2: 0.75;
$colcount-desktop: 2;
$colcount-tablet: 2;
$colcount-phone: 1;

/* styling variables */
$bigWidgetHeight: 30rem;
$carouselHeight: 24rem;
$fileExchangeHeight: 18.2rem;
$widgetGap: $off-15;
$cardHSpacing: $off-15;
$cardVSpacing: $off-10;
$pageContentPadding: $off-15;

/* build365 */
$col-build365-background: #f8e12b;
$col-build365-foreground: #002467;

/* entity detail */
$entity-detail-mobile-row-height: 42px;
$entity-detail-mobile-font-size: 14px;