app-overlay-header-bar,
app-header-bar {
  .header-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    &.with-tabs {
      justify-content: space-between;

      app-header-tabs {
        min-width: 0;
      }
    }

    .button-container {
      margin-right: 0.6rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: 0.6rem;

      .mat-slide-toggle-label {
        margin: 0 1.2rem;

        .mat-slide-toggle-content {
          color: $col-text-light;
        }
      }
    }
  }
}