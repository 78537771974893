.c4-hover-card:hover {
  .mat-card {
    background-color: $col-selected;
    box-shadow: 0px 1px 4px $col-border !important;
  }
}

.c4-bi-content>iframe {
  border: 1px solid $col-backcontrast;
}

.user-dropdown-menu {
  &>button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-button-wrapper {
    height: auto;
  }

  .c4-account-image {
    width: 2.8rem;
    height: 2.8rem;
    display: block;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }
}

div.appSection.flex {
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: space-between;
  margin-left: -0.9rem;
  margin-right: -0.9rem;
  flex-wrap: wrap;

  .c4-dashboard-item {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }
}

div.appSection.block {
  display: block;
}

@for $i from 1 through 10 {
  $width: percentage($i / 10);

  div.appSection.flex .c4-dashboard-item[data-width='#{$i}'] {
    width: $width;
    max-width: $width;
  }

  .c4-link-section .c4-link-box[data-width='#{$i}'],
  .c4-bi-section .c4-bi-box[data-width='#{$i}'] {
    width: calc(#{$width} - 1.54em);
    max-width: $width;
  }
}

div.appSection.flex .c4-dashboard-item[data-width='10'] {
  margin-bottom: 1.8rem;
}

//link component for dashboard
.appSection.top {
  margin-top: 0 !important;
}

.c4-dashboard-item.slim {
  margin-bottom: -1.8rem;
}

.c4-dashboard-item.xSlim {
  margin-bottom: -3.6rem;
}

.bottombanner {
  margin-top: 7.2rem;
}

.c4-portal-link {
  min-height: 100%;
  height: 100%;
  display: flex;
  width: 100%;
}

.c4-link-content ul {
  list-style: square;
  margin: 1.2rem 0 0 0;
  padding: 0.6rem 0.6rem 0.6rem 2.4rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
}

.c4-link-box,
.c4-bi-box {
  .mdi {
    font-size: $fontsize-icon;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 34vh;
  }
}

.c4-card-content-holder {
  position: relative;
  height: inherit;
}

.c4-link-section.horizontal,
.c4-bi-section.horizontal {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: flex-start;
  margin-left: -0.9rem;
  margin-right: -0.9rem;
  flex-grow: 1;
  width: inherit;

  .c4-link-box,
  .c4-bi-box {
    margin-right: 0.9rem;
    margin-left: 0.9rem;
    margin-top: 0 !important;
    margin-bottom: 1.8rem;

    &[data-width='10']:last-child {
      margin-bottom: 0;
    }
  }
}

mat-card.c4-ratio {
  position: relative;
  height: 0 !important;

  .c4-card-content-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: auto;
  }
}

// .c4-footer-bottom-pad .c4-footer-wrapper {
//   position: relative!important;
//   padding: 7.2rem 0 0 0!important;
//   opacity: .1!important;
//   visibility: hidden!important;
//   pointer-events: none!important;
// }