@import 'core/variables';
@import 'core/colors';
@import 'mixins/shadow';
@import 'mixins/inputs';

.c4-center-cell {
  text-align: center !important;
}

.p-component {
  font-family: inherit;
  font-size: inherit;
}

.p-link,
.p-inputtext {
  font-size: inherit;
}

// #region Tree

.p-tree {
  border: none;
  padding: 0;
  color: $col-text;
  background-color: $col-backcontrast;

  .p-tree-filter-container {
    margin-bottom: 0.5em;
    padding: 0;
    height: 3em;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    .p-inputtext {
      font: inherit;
      color: $col-text;
      background: $col-backcontrast;
      padding: 0.2em 0.35em;
      border: 0 none;
      border-bottom: 1px solid $col-selected;
      transition: none;
      border-radius: 0;

      &:enabled:focus {
        box-shadow: none; //inset 0 -1px 0px 0px $col-primary;
        border-bottom-color: $col-primary;
      }
    }
  }

  .p-tree-wrapper .p-tree-container .p-treenode {
    padding: 0;

    .p-treenode-content {
      height: 48px;
      padding: 0 0.25rem;
      border-radius: 0;

      &.p-treenode-selectable:hover {
        background-color: $col-hover !important;
      }

      &.p-highlight {
        color: $col-text;
        background-color: $col-hover;
      }

      .p-tree-toggler,
      .p-treenode-label {
        color: $col-text !important;
      }
    }
  }

  .p-tree-empty-message {
    margin-left: 1.2rem;
  }
}

p-tree.slim {
  .p-tree {
    .p-tree-filter-container {
      @include mat-input-underline;
      height: auto;
      margin: 0 0 0.5rem;
      border-top: 0.1rem solid $col-selected;

      &::before {
        border-color: $col-selected;
      }

      .p-inputtext {
        padding: 0 2.4rem;
        height: 4.8rem;
      }
    }

    .p-tree-empty-message {
      flex-grow: 1;
      padding: 1.8rem 1.2rem;
      text-align: center;
      opacity: 0.6;
    }

    .p-tree-container {
      // used to avoid cycle dependency
      --tree-node-indent-initial: 1;

      .p-treenode {
        --tree-node-indent: calc(var(--tree-node-indent-initial));

        .p-treenode-children {
          padding: 0;
          --tree-node-indent-initial: calc(var(--tree-node-indent) + 1);
        }

        .p-treenode-label {
          flex-grow: 1;
          min-width: 0;
        }

        .p-treenode-content {
          height: 3.6rem;
          padding: 0 2.4rem;
          padding-left: calc(var(--tree-node-indent) * 2.4rem);

          &:focus {
            border-left: solid 0.3rem $col-border;
            padding-left: calc(var(--tree-node-indent) * 2.4rem - 0.3rem); // - 0.3 for border
          }

          // padding: 0 2.4rem;

          // &:focus {
          //   border-left: solid 0.3rem $col-border;
          //   padding-left: 2.1rem;
          // }

          &.p-highlight {
            .tree-label {
              color: $col-primary;
            }
          }

          .p-tree-toggler {
            margin-right: 0.6rem;
            min-width: 2rem;
            min-height: 2rem;
            color: $col-text-light;
            border: 0 none;
            background: transparent;
            border-radius: 0;
            transition: none;

            &:enabled:hover {
              color: $col-text-light;
              border-color: transparent;
              background: transparent;
            }

            &.p-link {
              font-size: inherit;
            }
          }

          .p-checkbox {
            margin-right: 0.6rem;
            border-radius: 0;
            flex-shrink: 0;

            &:hover {
              box-shadow: none !important;
            }

            .p-checkbox-box {
              border-color: $col-text-light;
              border-radius: 0.3rem;
              position: relative;
              width: inherit;
              height: inherit;

              &.p-highlight {
                border-color: $col-primary;
                background: $col-primary;
              }

              .p-checkbox-icon {
                color: $col-backcontrast;
              }

              .pi {
                font-size: 1.6rem !important;

                &::before {
                  font-size: inherit !important;
                }
              }
            }
          }

          .tree-label-row {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            .tree-label {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .mdi {
              color: $col-text-light;
              font-size: $fontsize-icon;
            }
          }
        }
      }

      //material like select styling - full width
      // .p-treenode-children {
      //   padding: 0;

      //   .p-treenode {
      //     .p-treenode-content {
      //       padding-left: 4.8rem;

      //       &:focus {
      //         padding-left: 4.5rem; // 4.8 - 0.3 for border
      //       }
      //     }
      //   }

      //   .p-treenode-children {
      //     .p-treenode {
      //       .p-treenode-content {
      //         padding-left: 7.2rem;

      //         &:focus {
      //           padding-left: 6.9rem; // 7.2 - 0.3 for border
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

// #endregion

// #region Tree Select

body>.p-treeselect-panel {
  background-color: transparent;

  .p-tree {
    border: none;
    padding: 0;
    color: $col-text;
    background-color: $col-backcontrast;

    .p-tree-filter-container {
      border-bottom: solid 1px $col-border;
      margin: 0;

      .p-tree-filter.p-inputtext {
        background: $col-backcontrast;
        width: calc(100% - 1em);
        margin: 0.6rem;
        border: none;
        border-radius: 0;

        &::placeholder {
          color: $col-text;
        }

        &:enabled:focus {
          box-shadow: none !important;
          border-color: $col-primary;
        }
      }
    }

    .p-treenode {
      padding: 0 1px;

      .p-treenode-content {
        border-radius: 0;
        height: 3.6rem;

        &.p-treenode-selectable:hover {
          background-color: $col-background;
        }

        &.p-highlight {
          color: $col-text;
          background-color: $col-background;
        }

        .p-tree-toggler,
        .p-treenode-label {
          color: $col-text !important;
        }
      }
    }

    .p-tree-empty-message {
      margin: 0;
      padding: 1.2rem;
    }
  }
}

// #endregion

// #region Dropdown (Select)

.p-dropdown {
  width: 100%;
  border-width: 1px;
  border-color: $col-border;

  &:not(.p-disabled) {
    &:hover {
      border-color: $col-border;
    }

    &.p-focus {
      border-color: $col-border;
    }
  }

  .p-dropdown-clear-icon {
    margin: 0;
    transform: translate(0, -50%);
  }

  .p-dropdown-panel {
    max-width: 100%;
  }

  .p-dropdown-label {
    border: solid transparent 1px; // increase height to 3.6rem
  }
}

.dropdown-form-field {
  flex: 1;

  .p-dropdown {
    border-radius: 0;
    border-bottom: solid $col-border;
    border-width: 0 0 1px 0;
    padding-bottom: 1px;

    &:not(.p-disabled) {
      &:hover {
        border-bottom: solid $col-border;
        padding-bottom: 1px;
        border-width: 0 0 1px 0;
      }

      &.p-focus {
        padding-bottom: 0;
        border-bottom: solid $col-primary;
        border-width: 0 0 2px 0;
      }
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-header {
    padding: 0;

    .p-dropdown-filter-container {
      padding: 0;

      .p-inputtext {
        box-shadow: none;
        padding: 1.2rem;
        margin-bottom: 2px;
        border: none;

        &:enabled:focus {
          margin: 0;
          border-bottom: 2px solid $col-primary;
        }
      }
    }
  }

  .p-dropdown-items {

    .p-dropdown-item-group,
    .p-dropdown-item,
    .p-dropdown-empty-message {
      height: 4.2rem;
      padding: 0 1.2rem;
      display: flex;
      align-items: center;
    }

    .p-dropdown-item {
      color: $col-text-light !important;

      &:hover {
        background-color: $col-hover;
      }

      &.p-highlight {
        background-color: $col-border;
      }

      &.p-focus {
        background-color: $col-selected;
      }
    }

    .p-dropdown-item-group {
      font-weight: 500;
      color: $col-text-light
    }
  }
}

// #endregion

// #region Multiselect

.p-multiselect-panel {
  background-color: $col-backcontrast;

  .p-multiselect-header {
    color: $col-text;
    background-color: $col-backcontrast;

    .p-multiselect-filter-container {
      padding: 0;

      .p-inputtext {
        box-shadow: none;
        padding: 1.2rem;
        margin-bottom: 2px;
        border: none;

        &:enabled:focus {
          margin: 0;
          border-bottom: 2px solid $col-primary;
        }
      }
    }

    .p-multiselect-close {
      margin-top: 0;
      color: $col-text;

      &:hover {
        background-color: $col-background;
        color: $col-text;
      }
    }
  }

  .p-multiselect-items {
    .p-multiselect-empty-message {
      color: $col-text;
    }

    .p-multiselect-item {
      color: $col-text;
      padding: 1.2rem 1.2rem;

      &.p-highlight {
        color: $col-primary-contrast;
        background: $col-primary;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        color: $col-text;
        background: $col-background;
      }
    }
  }
}

// #endregion

body {
  .mat-input-element::placeholder {
    color: $col-text-light !important;
  }
}

body .c4-p-table-container {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  height: 100%;

  .p-datatable-scrollable-header,
  .p-treetable-scrollable-header {
    overflow: visible !important;
  }

  .mat-form-field {
    display: block;
    position: relative;
    text-align: left;
    margin: 0;
    max-width: 24rem;
    width: 50%;
  }

  .p-widget {
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
  }

  .p-multiselect-trigger-icon {
    color: $col-border;
  }

  .p-dropdown .p-dropdown-trigger {
    background-color: transparent;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
  }

  .c4-no-focus {
    .mdi {
      font-size: $fontsize-icon;
    }

    .mat-button-focus-overlay {
      display: none !important;
    }
  }

  @media #{$mat-sm},
  #{$mat-xs} {
    .c4-grid-scrollable .p-datatable-wrapper {
      min-height: 18rem;
    }
  }

  .p-datatable,
  .p-treetable {

    .p-datatable-wrapper {
      border-radius: 0.4rem;
    }

    .p-datatable-table {
      table-layout: fixed;
    }

    .p-datatable-scrollable-header,
    .p-datatable-scrollable-footer {
      background-color: transparent;
    }

    .p-treetable-tbody>tr>td {
      border: 0 none !important;
    }

    .p-datatable-caption.p-widget-header {
      text-align: left;
      padding: 0 0.6rem;
    }

    .c4-grid-empty-warning {
      td {
        padding: 2.4rem 1.7rem 3.6rem 1.7rem !important;
        text-align: center !important;
        letter-spacing: 0.1rem;
        font-weight: 400 !important;
        color: $col-text-light !important;

        span.c4-grid-empty-ico {
          font-size: $fontsize-icon;
          padding-right: 1.5rem;
        }
      }
    }

    .p-datatable-caption,
    .p-datatable-summary {
      background-color: transparent;
      color: inherit;
      border: 0 none;
    }

    .p-datatable-thead {
      &>tr {
        background: transparent;
        border: 0 none;

        &>th {
          font-weight: 500;
          color: inherit;
          background: transparent;
          border: 0 none;
          position: relative;
        }
      }

      &>tr:first-child>th {
        color: $col-primary !important;

        * {
          color: inherit !important;
        }
      }

      &>tr>th {
        background: transparent !important;
        border: 0 none;
        white-space: nowrap;
        text-align: left;
        padding: 0.25em 0.5em 0.25em 0.5em;
        line-height: 1.5em;
      }

      tr+tr th {
        &>* {
          line-height: inherit;
        }
      }
    }

    .p-datatable-tbody {

      tr,
      tr:focus {
        background: transparent;
        color: inherit;
      }

      tr:not(.p-highlight, .c4-grid-empty-warning):hover {
        background: $col-selected;
        color: inherit;
      }

      tr td {
        background: transparent;
        overflow: visible;
        border: solid $col-selected !important;
        border-width: 1px 0 0 0 !important;
        padding: 1em 0.5em 1em 0.5em;
        line-height: 1.5em;
        vertical-align: middle;
      }

      .c4-grid-cell {
        position: relative;
        line-height: inherit;

        .c4-grid-row-link {
          position: absolute;
          top: -12rem;
          right: -6rem;
          left: -6rem;
          bottom: -12rem;
          z-index: 5;
        }

        .c4-grid-btn {
          position: relative;
          cursor: pointer;
          font-size: inherit;

          .mdi:before {
            vertical-align: baseline;
          }

          &.inactive-btn {
            pointer-events: none;
            cursor: default;
          }
        }
      }

      .c4-ctrl-td {
        cursor: pointer;
        vertical-align: middle !important;

        .c4-visibility-control {
          position: relative;
          text-align: center;
          height: 1.5em;

          .mdi.mdi-chevron-down {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            display: inline-block;
            color: $col-text-light;
            font-size: $fontsize-icon;
          }

          &.c4-disabled {
            .mdi.mdi-chevron-down {
              color: $col-border;
            }
          }
        }
      }

      .c4-topline {
        display: block;
        line-height: inherit;
        padding: 0;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;

        &+span {
          color: $col-text-light;
          font-size: 1.3rem;
        }

        @media #{$mat-xs} {
          word-break: break-all !important;
        }

        @media #{$mat-sm} {
          word-break: break-all !important;
        }
      }

      .c4-line-with-dot {
        position: relative;
        font-weight: bold;

        &:before {
          content: ' ';
          height: 1.2rem;
          width: 1.2rem;
          background-color: $col-warning;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          left: -1.5rem;
          top: 0.3rem;
        }
      }

      .c4-cell-col {
        font-size: 1.3rem;
        display: inline-block;

        &.c4-label {
          min-width: 30%;
        }
      }

      .c4-expanded .mdi.mdi-chevron-down::before {
        transform: rotate(180deg);
      }

      .c4-link-row:hover {
        background-color: $col-hover !important;
      }

      .c4-main-row+.c4-subrow {
        display: none;
        cursor: pointer;
      }

      .c4-main-row.c4-expanded+.c4-subrow {
        display: table-row;
      }

      .c4-main-row.awesome-class {
        color: $col-backdrop;

        opacity: 0.4;
        pointer-events: none;

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          color: $col-backdrop;

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .c4-expanded {
        box-shadow: 0 0 0 !important;
      }

      .c4-expanded,
      .c4-subrow {
        &:not(.c4-selected-row) {
          background: $col-hover !important;
        }
      }

      .c4-subrow td {
        background-color: transparent !important;
        padding-left: 1.8rem;
      }
    }
  }

  /*custom inputs /adjustments*/
  .p-multiselect .p-multiselect-label,
  .p-dropdown .p-dropdown-label {
    text-align: left;
    padding: 0.5rem 2.4rem 0.5rem 0.5rem;
    color: inherit;
  }

  .p-inputtext {
    font-size: inherit;
    color: inherit;
    background: inherit;
  }

  .c4-filter-row {
    &:not(.c4-no-filter) {
      @include mat-input-underline(0.5rem, 0.7rem);
      overflow: visible; // needed for resizable columns
    }

    .date-filter {
      min-height: 3.2rem;
      display: flex;
      position: relative;
      align-items: center;

      .mat-datepicker-toggle {
        position: absolute;
        right: 0;
      }
    }
  }

  th {

    .p-multiselect,
    .p-dropdown,
    input.p-inputtext {
      position: relative;
      width: inherit;
      min-width: 4.8rem;
      background: inherit !important;
      border: 0 !important;
      padding: 0;
      border-radius: 0;
    }
  }

  .p-multiselect:not(.p-state-disabled):hover {
    border-color: inherit;
  }

  .p-multiselect:not(.p-state-disabled).p-focus {
    box-shadow: none;
  }

  .p-dropdown .p-dropdown-trigger,
  .p-multiselect .p-multiselect-trigger {
    background: inherit;
    color: inherit;
  }

  .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon,
  .p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
    top: auto;
    left: auto;
    margin-top: 0.1rem;
    margin-left: 0;
    position: static;
  }

  .p-dropdown .p-dropdown-clear-icon {
    position: absolute;
    right: 2.4rem;
    top: 50%;
    height: 1.6rem;
    margin-top: 0;
    transform: translateY(-60%);
  }

  // ::placeholder {
  //   color: $col-text;
  // }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

/*pagination*/
.p-datatable .p-paginator-bottom {
  border-width: 0;
  border-radius: 0;
  height: 4.8rem;
}

.p-paginator {
  background-color: $col-backcontrast;
  padding: 0;
  border: 0 none;

  .p-paginator-element {
    background-color: transparent;
    color: $col-text-light !important;
    border-radius: 0.6rem;
    box-shadow: inset 0 0 0 3px $col-backcontrast;
    font-weight: 400;
    min-width: 2.7rem;
    height: 2.7rem;

    &.p-highlight {
      background-color: transparent !important;
      color: $col-primary !important;
      font-weight: 500;
    }
  }

  .p-paginator-pages .p-paginator-page {
    box-shadow: none !important;
    min-width: 4.2rem;
    height: 4.2rem;
    margin: 0.2rem;
  }

  .p-dropdown {
    height: auto;
    color: $col-text-light !important;
    min-width: 7.2rem;
    background: inherit !important;
    border: 0 !important;
    padding: 0;
    border-radius: 0;

    .p-dropdown-label,
    .p-dropdown-trigger {
      color: inherit;
    }

    .p-dropdown-panel {
      background-color: $col-backcontrast;
    }
  }
}

// peditor/quil
.p-editor-container {
  .p-editor-toolbar {
    border: 1px solid $col-selected !important;
    background: $col-backcontrast !important;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;

    .ql-stroke {
      stroke: $col-text-light !important;
    }

    .ql-fill {
      fill: $col-text-light !important;
    }

    &.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      background: $col-background !important;
      border-radius: 0.3rem;
    }
  }

  .p-editor-content {
    border: 1px solid $col-selected !important;

    .ql-editor {
      background: $col-backcontrast !important;
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }
  }
}

.c4-grid-btn.details-link-btn .mdi::before {
  font-size: 1.8rem;
}

.p-sortable-column-badge {
  // position: absolute;
  // top: -0.4rem;
  // left: 0.4rem;
  display: none !important;
}

/*mdi to prime- Icons */
.p-paginator .p-paginator-icon {
  display: inline-block;
  position: static;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-left: 0;

  &:before {
    font-size: 1.7rem;
  }
}

th .pi {
  padding-left: 0;
}

.pi {
  display: inline-block;
  padding: 0;
  font-family: 'Material Design Icons';
  font-size: 1.4rem !important;
  vertical-align: baseline;
  position: relative;
  color: inherit;
  font-style: normal;
}

.pi-angle-right:before {
  content: '\f0142';
}

.pi-angle-left:before {
  content: '\f0141';
}

.pi-angle-double-right:before {
  content: '\f013e';
}

.pi-angle-double-left:before {
  content: '\f013d';
}

.pi-sort-alt:before {
  content: '\f04bf';
}

.pi-sort-amount-down:before {
  content: '\f0045';
}

.pi-sort-amount-up-alt:before {
  content: '\f005d';
}

.pi-chevron-down:before {
  content: '\f0140';
  font-size: 2.4rem;
}

.pi-chevron-right:before {
  content: '\f0142';
  font-size: 2.4rem;
}

.pi-times:before {
  content: '\f0156';
  font-size: 2.4rem;
}

.pi-minus {
  display: flex;

  &:before {
    content: '\f0374' !important;
    font-size: 1.8rem;
  }
}

.pi-check:before {
  content: '\f012c' !important;
  font-size: 1.8rem !important;
  position: relative !important;
  border: 0 none !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
  animation: none !important;
}

.p-checkbox {

  .p-checkbox-box,
  .p-checkbox-box:hover {
    border-color: $col-text !important;
    background: transparent !important;

    &.p-highlight {
      border-color: $col-accent !important;
      background: $col-accent !important;

      .p-checkbox-icon {
        color: $col-accent-contrast !important;
      }
    }
  }
}