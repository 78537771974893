@import 'core/colors';

@mixin default-box-shadow() {
  @include custom-box-shadow($col-border);
}

@mixin custom-box-shadow($color, $baseSize: 1px) {
  box-shadow: 0 $baseSize (
    $baseSize * 3) $color;
}

@mixin default-box-shadow-inset() {
  @include custom-box-shadow-inset($col-border
);
}

@mixin custom-box-shadow-inset($color, $baseSize: 5px) {
  box-shadow: inset 0 $baseSize (
    $baseSize * 3) $color;
}

@mixin default-text-shadow() {
  @include custom-text-shadow($col-border
);
}

@mixin custom-text-shadow($color, $baseSize: 1px) {
  text-shadow: 0 $baseSize (
    $baseSize * 3) $color;
}