@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming' as mat-theming;
@use 'angular-material-css-vars-legacy/main' as mat-css;
@use 'angular-material-css-vars-legacy/public-util' as mat-css-utilities;

// $custom-typography: mat.define-legacy-typography-config($font-family: var(--font));
// // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
// //  The following line adds:
// //    1. Default typography styles for all components
// //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
// //  If you specify typography styles for the components you use elsewhere, you should delete this line.
// //  If you don't need the default component typographies but still want the hierarchy styles,
// //  you can delete this line and instead use:
// //    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();

// $c4-app-primary: mat.define-palette($myprime);
// $c4-app-accent: mat.define-palette($mysecondary);
// $c4-app-warn: mat.define-palette(mat.$orange-palette);
// $theme: mat.define-light-theme($c4-app-primary, $c4-app-accent, $c4-app-warn);
// @include mat.all-legacy-component-themes($theme);

// optional
$mat-css-dark-theme-selector: '.isDarkTheme';
$mat-css-light-theme-selector: '.isLightTheme';

@mixin app-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // Define any styles affected by the theme.
  // .app-header {
  //   // Use mat-color to extract individual colors from a palette.
  //   // background-color: mat-color($primary);
  //   border: medium solid mat-theming.mat-color($accent, A400);
  // }
}

$custom-typography: mat.define-typography-config($font-family: var(--font));
// $body-1: mat-theming.mat-typography-level(16px, 24px, 500),
// $headline-1: mat-theming.mat-typography-level(16px, 24px, 500)

@include mat-css.init-material-css-vars($typography-config: $custom-typography) using($mat-css-theme) {
  @include app-theme($mat-css-theme);
}

// @include mat-css-utilities.mat-css-set-palette-defaults($myprime, 'primary');
// @include mat-css-utilities.mat-css-set-palette-defaults($mysecondary, 'accent');
// @include mat-css-utilities.mat-css-set-palette-defaults($mysecondary, 'warn');


// $theme-text-light: mat.get-color-from-palette($c4-app-primary, foreground-text);
// $theme-text-light: mat.get-color-from-palette($c4-app-primary, foreground);
// $foreground-palette: mat.map-get($c4-app-primary, foreground);
// $theme-text-light: mat.get-color-from-palette($foreground-palette, contrast);

// $color-config: mat.get-color-config($theme);

// $foreground-palette: map.get($color-config, 'primary');
// $col-text-light: mat.get-color-from-palette($foreground-palette, lighter);

// $warn-palette: map.get($color-config, 'warn');

:root {
  // --warn-color: mat.get-color-from-palette($warn-palette, default);
  // --warn-color: mat-css-utilities.mat-css-color-warn(500);

  // todo replace with foreground color after upgrade to angular v17
  --text-color: 47, 47, 47;

  // todo enable when introducing dark theme
  // body.is-dark-theme {
  //   --text-color: 255, 255, 255;
  // }
}