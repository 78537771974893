@import 'core/colors';

body[page$='bim'] {

  .cdk-overlay-container,
  .toolbars-container {
    z-index: 10005; // xeokit-sdk uses 10003 for its overlays
  }

  app-bim-navigator {
    .bim-tree-container {
      ul {
        margin: 0;
        padding-left: 3.6rem;

        list-style: none;

        grid-column: 1 / span 3;

        li {
          position: relative;
          display: grid;
          grid-template-columns: 3rem auto;
          grid-template-rows: minmax(3.6rem, 1fr);

          &.highlighted-node {
            background-color: $col-primary;
            color: $col-primary-contrast;
          }

          >a {
            position: absolute;
            top: 0.6rem;
            left: -2.4rem;

            display: flex;
            align-items: center;
            justify-content: center;

            // margin: 0.3rem;
            width: 2.4rem;
            height: 2.4rem;

            background-color: $col-background;
            border-radius: 50%;
          }

          >input {
            margin: 0;
            margin: 0 0.6rem;
          }

          >span {
            align-self: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .xeokit-camera-pivot-marker {
    color: #ffffff;
    line-height: 1.8;
    text-align: center;
    font-family: "monospace";
    font-weight: bold;
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    border: 2px solid #ebebeb;
    background: black;
    visibility: hidden;
    box-shadow: 5px 5px 15px 1px #000000;
    z-index: 1000000;
    pointer-events: none;
  }
}