@import 'core/variables';

// #region Plan GeoJSON Fullscreen

.app-geojson-editor-fullscreen {
  .tasklike-detail-content {
    .content-container {
      .panel-plan {
        background-color: #fff;
        position: fixed;
        height: 100vh; // calc(100vh - env(safe-area-inset-top));
        width: 100%;
        left: 0;
        top: 0; // env(safe-area-inset-top);

        .panel-image {
          background-color: #fff;
          height: 100%;
        }

        .mdi.mdi-image-off-outline {
          display: none;
        }
      }
    }
  }

  .mat-dialog-title {
    display: none;
  }

  .tasklike-edit-container {
    display: none;
  }
}

// #endregion

// state dropdown
.tasklike-state-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

@mixin dialogTop {
  .dialog-header-ico {
    font-size: 1.3em;
    padding-right: 0.25em;
  }

  .dialog-top {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    line-height: 2.1em;
    height: 2.2em;
    padding-left: 16px;

    .dialog-header {
      padding: 0;
      display: flex;
      align-content: center;
      color: $col-text-light;
      font-size: 1em;
      font-weight: 400;

      .dialog-header-label {
        text-decoration: underline;
        color: $col-primary;
        cursor: pointer;
      }

      >div {
        display: flex;
        align-items: center;

        &.error {
          color: $col-warning;
          text-transform: initial;

          .mdi {
            margin: 0 0.6rem;
          }
        }
      }
    }

    .dialog-controls {
      .mat-icon-button {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        border-radius: 0;
      }
    }
  }
}

.integrated-edit-dialog-container {
  @include dialogTop;

  .integrated-dialog-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    float: right;
    padding-right: 0.5em;

    .action-ico {
      font-size: $fontsize_old_icon;
    }

    .mat-flat-button {
      height: 4.2rem;
      margin-right: 0.6rem;
    }

    .mat-icon-button {
      width: 4.2rem;
      height: 4.2rem;
      line-height: 4.2rem;
      border-radius: 4.2rem;
    }
  }
}

.cdk-overlay-pane.tasklike-dialog {
  max-width: 100vw !important;
  max-height: 100vh !important;

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
    overflow: hidden;

    .safe-area {
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 0; // env(safe-area-inset-top, 0px);
      background-color: $col-backcontrast;
      width: 100%;
      z-index: 1000;
    }
  }
}

@media screen and (max-width: 1444px) {
  .cdk-overlay-pane.tasklike-dialog {
    max-width: 100vw !important;
    width: 100vw;
    height: 100%;
  }
}

.c4-dialog-wrapper.dialog-wide {
  min-width: 33em;
  width: 80vw;
  max-width: 99vw;
  min-height: 16rem;
  height: 80vh;
  padding: 0;
  padding-top: 0 !important; // calc(0 + env(safe-area-inset-top)) !important;
  position: relative;
  margin: 0;

  &.fullscreen {
    position: absolute;
    top: 0; // env(safe-area-inset-top, 0px);
    left: 0;
    bottom: 0;
    border-radius: 0;
    padding: 0;
    background: $col-backcontrast;
    width: 100vw;
    z-index: 999;
    height: auto;
    max-height: 100%;
    max-width: 100vw;
    min-width: 12rem;
    overflow: auto;
    margin: 0 !important;

    .mat-dialog-content {
      max-height: calc(100vh - 5em);

      .tasklike-body .tasklike-tab-container .mat-tab-body-wrapper {
        height: calc(100vh - 13.2em);
      }
    }
  }

  &.with-state {
    .state-border {
      position: absolute;
      width: 0.5rem;
      height: 10.1rem;
      top: 0;
      left: 0;
      z-index: 0;
      pointer-events: none;
    }
  }

  .tasklike-dialog-title {
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0 0 1.2rem 0;
    text-transform: uppercase;
    @include dialogTop;

    .dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 1.2rem;
      position: relative;
      float: right;
      padding-right: 0.6rem;

      .action-ico {
        font-size: $fontsize-icon;
      }

      .mat-flat-button {
        height: 4.2rem;
        display: flex;
        align-items: center;
      }

      .mat-icon-button {
        width: 4.2rem;
        height: 4.2rem;
        line-height: 4.2rem;
        border-radius: 4.2rem;
      }
    }
  }

  .mat-dialog-content {
    padding: 0;
    margin: 0;
    overflow: visible;

    &.isPhone {
      overflow: auto;
    }
  }
}

.c4-dialog-wrapper.dialog-wide,
.integrated-edit-dialog-container {
  &.full-mobile {
    font-size: 1.3vw;

    &.phone {
      font-size: 4vw;

      .tasklike-dialog-title .dialog-actions {
        .mat-icon-button {
          width: 2.8em;
          height: 2.8em;

          .action-ico::before {
            font-size: 3.4rem;
          }
        }
      }

      .tasklike-body .tasklike-tab-container .mat-tab-label {
        height: 3em;
        padding: 0 1em;
        min-width: 3em;
        line-height: 3em;

        .tab-label-ico {
          font-size: 3.4rem;
        }
      }
    }
  }
}

@mixin uploads {
  display: flex;
  flex-direction: column;
  min-width: 24rem;
  max-width: 36rem;
}

.tasklike-files-content {
  padding: 0.6rem 0 7.7rem 0;

  .upload-container {
    display: flex;
    align-items: stretch;
    padding-top: 1.2rem;

    @media #{$mat-lt-md} {
      flex-direction: column-reverse;
    }

    app-upload {
      flex-grow: 1;
      margin-left: 1.2rem;
      min-height: 18rem;
      height: 18rem;
      max-height: 18rem;
      font-size: large;

      @media #{$mat-lt-md} {
        min-height: 12rem;
        height: 12rem;
        max-height: 12rem;
        margin: 0 0 1.2rem 0;
      }
    }

    .upload-placeholder {
      @include uploads;
      align-items: center;
      justify-content: center;

      span {
        font-size: 1.4rem;
      }
    }

    .upload-list {
      @include uploads;
      align-items: stretch;

      .upload-item-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        background-color: $col-background;
        border-radius: 0.3rem;

        .upload-item {
          display: flex;
          align-items: center;
          padding: 0 0.6rem;

          .mdi {
            font-size: $fontsize-icon;
          }

          .item-name {
            font-size: larger;
            color: $col-text;
            padding-left: 0.6rem;
          }
        }

        button {
          border-radius: 0 0.3rem 0.3rem 0;

          .mdi {
            font-size: $fontsize-icon;
            vertical-align: unset;
          }
        }
      }
    }
  }

  .c4-docgrid-container {
    box-shadow: none !important;
    padding: 1.8rem 0 1.2rem 0;

    .p-datatable-resizable>.p-datatable-wrapper {
      overflow: visible;
    }

    .p-datatable-thead {
      position: sticky;
      background: $col-backcontrast;
      z-index: 98;
      box-shadow: 0 1px 0 0 $col-selected;
    }
  }

  .integrated-button-container {
    display: flex;
    justify-content: center;
    background: $col-backcontrast;
    position: sticky;
    top: 0px;
    z-index: 99;

    .c4-upload-ctrl {
      .mat-button-wrapper {
        min-width: 24rem;
        justify-content: center;
      }

      .mdi {
        color: $col-backcontrast;
      }
    }
  }
}

.tasklike-body {
  .latest-user {
    margin: 0 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .prefix-label {
    display: inline-block;
    text-align: left;
    padding-right: 0.8rem;
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.state {
      padding-right: 2.4rem;
    }
  }

  .ico {
    margin: -0.1rem 0 0 0;
    font-size: 1.3em;
    opacity: 0.4;
  }

  .prefix {
    position: absolute;
    left: 0;

    &.label {
      opacity: 0.6;
    }
  }

  .expansion-panel-header-like {
    display: flex;
    align-items: center;
    margin: $slim-panel-margin;
    border-bottom: 1px solid $col-selected;
    height: $slim-panel-height;
    line-height: $slim-panel-height;
    font-weight: 500;
    font-size: 1em;

    .error {
      font-weight: 400;
      color: $col-warning;
      opacity: 0.8;
      white-space: nowrap;
      overflow-y: hidden;
      margin-left: 0.6rem;

      .mdi {
        margin-right: 0.6rem;
      }
    }
  }

  .tasklike-edit-container {
    form {
      .ico-state-select {
        margin: 0 0 0 -0.25em;
        font-size: 1em;
        position: absolute;
        transform: translatex(-100%);
      }

      .tasklike-form-row {
        flex-grow: 2;
        position: relative;
      }

      .tasklike-form-hilight {
        opacity: 0;
        pointer-events: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;
        height: 1px;
        background: $col-primary;
        left: 0;
        right: 0.6rem;
        bottom: -0.1rem;
      }

      .tasklike-input {
        font-size: inherit;
        font-family: inherit;
        border: 0;
        padding: 0;
        margin: 0;
        display: block;
        width: calc(100% - 1em);
        color: $col-text;
        background: transparent;

        &:focus+.tasklike-form-hilight {
          opacity: 1;
        }

        &.error+.tasklike-form-hilight {
          opacity: 1;
          background-color: $col-warning;
        }
      }
    }

    .tasklike-edit-header {
      height: 5.8rem;
      margin: 0;
      padding: 0 16px;

      .tasklike-header-id {
        padding: 0 0.6rem 0 0;
        display: block;
        text-align: right;
        min-width: 1.6rem;

        &.hide {
          visibility: hidden;
        }
      }

      .header-row {
        &>div {
          display: flex;
          align-items: center;
        }

        .header-row-title {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2rem;
          position: relative;
          top: -0.6rem;
        }
      }

      .header-row-subtitle {
        padding: 0.5rem 0 0 0;

        .form-field {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .tasklike-top-container {
      display: flex;
      background: $col-hover;
      padding: 1rem 16px;
      margin: 0 0 -4.2rem 0;
      height: 8.4rem;
      border-top: 1px solid $col-border;
      border-bottom: 1px solid $col-border;
      flex-direction: row;
      justify-content: space-between;

      .tasklike-top-info {
        height: 100%;
        line-height: 3rem;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        gap: 1.2rem;

        .top-info-col {
          min-width: 0;

          .top-info {
            padding-left: 0;
            position: relative;
            z-index: 90;
            display: flex;
            align-items: center;

            .prefix-label {
              flex-grow: 1;
            }

            .right {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .tasklike-tab-container {
    .mat-tab-body.mat-tab-body-active {
      overflow: hidden;
    }

    .mat-tab-body-wrapper {
      transition: unset;
      height: calc(80vh - 13em);
      margin-right: 0;

      .mat-tab-body-content {
        display: flex;
        overflow: auto;
        padding: 0 16px;

        >* {
          flex-grow: 1;
        }
      }
    }

    &.phone {
      .mat-tab-body-wrapper {
        .mat-tab-body-content {
          display: unset;
        }
      }
    }

    .mat-tab-header {
      border-bottom: 0 none;

      .mat-tab-header-pagination {
        display: none !important;
      }
    }

    .mat-tab-label {
      border: 1px solid $col-border;
      margin: 0 0 0 -1px;
      border-bottom-color: transparent;
      height: 4.2rem;
      padding: 0 1.2rem;
      min-width: 4.2rem;
      line-height: 4.2rem;

      &+.mat-tab-label {
        border-left-color: transparent;
      }

      .tab-label-ico {
        font-size: $fontsize-icon;
      }

      &.mat-tab-label-active {
        background-color: $col-backcontrast;
        border-left-color: $col-border !important;
        border-bottom-color: $col-primary !important;
      }
    }

    .mat-tab-body-content {
      min-height: 28.8rem;
    }

    // detail-content
    .tasklike-detail-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.7rem 0 1.8rem 0;

      .multiline-comment {
        white-space: pre-line;
        line-height: 1.2em;
        font-size: inherit;
      }

      .comment-edit {
        font-size: inherit;
        line-height: 1.2em;
        resize: none;
      }

      .description-edit {
        font-size: inherit;
        line-height: 1.2em;
        resize: none;
        border-bottom: 1px solid $col-selected;
      }

      .mat-expansion-indicator::after {
        border-width: 0 0.2em 0.2em 0;
        padding: 0.24em;
      }

      .mat-expansion-panel.slim-expansion-panel {
        .mat-form-field.currency {
          color: $col-text-light;
        }

        .mat-expansion-panel-header {
          h3 {
            margin: 0;
          }
        }

        .mat-expansion-panel-body {
          padding: 0 0 0.6rem 0;
        }

        .mat-expansion-panel-content {
          margin-top: 1.2rem;
          font-size: 1.4rem;

          .image {
            width: 100%;
          }

          h4 {
            margin: 0 0 0.6rem;
            border-bottom: 1px solid $col-selected;
          }
        }
      }

      .content-container {
        display: flex;
        flex-direction: column;

        &.content-a {
          min-width: 60%;
          width: 60%;
          max-width: 60%;
          gap: 1.2rem;

          .expansion-panel-header-like {
            margin: 0;
          }
        }

        &.content-b {
          margin-right: 0.2rem;
          min-width: 38%;
          width: 38%;
          max-width: 38%;
        }

        .panel-title {
          font-weight: 500;
          font-size: 1em;
        }

        mat-form-field {
          margin-top: 0.8rem;

          &.margin-top-none {
            margin-top: 0;
          }
        }

        ul.comment-list {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 1.2rem;

            &>.mdi {
              font-size: 2.5em;
              color: $col-text-light;
              line-height: normal;
              margin-right: 0.2em;
            }

            .comment {
              flex-grow: 1;
              display: flex;
              border: solid 1px $col-selected;
              border-radius: 0.4rem;
              padding: 1.2rem;

              &.isEdit {
                padding: 0 1.2rem;

                .buttons {
                  margin: 1.2rem 0;
                }
              }

              .mat-icon-button {
                width: 3.6rem;
                height: 3.6rem;
                line-height: 3.6rem;
                border-radius: 3.6rem;
                font-size: inherit;
              }

              .content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .header {
                  margin-bottom: 0.5em;

                  span:first-child {
                    font-weight: 500;
                    color: $col-text-light;
                  }
                }
              }

              .buttons {
                display: flex;
                margin-left: 1.2rem;

                .mdi {
                  color: $col-text-light;
                  font-size: 1.3em;
                }
              }
            }
          }
        }
      }
    }
  }

  //tab/file-loading
  .loading-container {
    display: flex;
  }

  //tab/history-changes
  .change-data>span {
    border: 1px solid $col-selected;
    box-shadow: inset 1px 1px 3px $col-selected;
    text-shadow: 1px 1px 3px $col-border;
  }
}

.tasklike-body .tasklike-edit-container .tasklike-top-container {
  display: flex;
  padding: 1rem 16px;
  margin: 0 0 -4.2rem 0;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
}

.tasklike-body .tasklike-edit-container .tasklike-top-container .tasklike-top-info.maxrows {
  height: 100%;
  line-height: 3rem;
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 0.1rem;
  width: 100%;
  flex-wrap: wrap;
}

.top-info-col.row-flex-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  max-width: calc(50% + 4.2rem);
}

//  mobile - phone
.cdk-overlay-pane.tasklike-dialog .mat-dialog-container .c4-dialog-wrapper.dialog-wide.fullscreen.full-mobile.phone {
  //padding-top: calc(1.5em + env(safe-area-inset-top)) !important;
  padding-top: 0;

  &.with-state {
    padding: 0 0 0 0.35em;
  }

  // .tasklike-tab-container {
  //   margin: 0.6rem -0.6rem 4.2rem -0.4rem;
  // }
}

.c4-dialog-wrapper.dialog-wide,
.integrated-edit-dialog-container {
  &.fullscreen.full-mobile.phone {

    textarea.tasklike-input,
    .tasklike-input-header {
      display: block;
      height: 2.6em;
      max-height: 2.6em;
      overflow: auto;
      font-family: inherit;
      min-height: 2.6em;
      line-height: 1.3em;
      font-size: inherit;
      resize: none;
      background: $col-hover;
      padding: 0.25em 0.75em 0.25em 0.25em;
      box-sizing: content-box;

      +.tasklike-form-hilight {
        right: 0;
      }
    }

    &::before {
      position: absolute;
      width: 0.35em;
      height: 100%;
    }

    .tasklike-dialog-title {
      font-size: 1em;
      margin: 0;
      position: sticky;
      top: 0em;
      right: 0em;
      left: 0em;
      background: $col-backcontrast;
      z-index: 2;
      box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.1);
    }

    .dialog-top {
      padding: 0;

      .dialog-header {
        width: 100%;
        margin: 0 2.2em 0 0.5em;
        overflow: hidden;

        .dlg-header-label {
          flex-grow: 1;
        }

        .error {
          font-size: 0.8em;
          white-space: nowrap;
          overflow: auto;
          text-overflow: ellipsis;
          min-width: 0;
          flex-shrink: 1;

          .mdi {
            margin: 0 0.2em;
          }
        }
      }
    }

    .dialog-actions,
    .integrated-dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      position: fixed !important;
      float: none;
      bottom: 0;
      left: 0;
      right: 0;
      background: $col-background;
      padding: 0;
      height: 3.4em;
      z-index: 9;
      align-items: center;

      .mat-flat-button {
        display: block;
        white-space: nowrap;
        text-decoration: none;
        margin: 0 0.25em;
        min-width: 2.8em;
        padding: 0 1em;
        border-radius: 0.25em;
        overflow: visible;
        height: 2.8em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // .mat-dialog-content {
    //   padding: 0 0.5em;
    // }
    .tasklike-body {
      padding: 0 0.5em;

      padding-top: 1.5em;

      .expansion-panel-header-like {
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .prefix {
        position: relative;
      }

      .header-row-title-phone {
        display: block;

        .tasklike-input-wrapper {
          width: 100%;
          font-size: 1.2em;
          position: relative;
          margin-bottom: 0.5em;
          display: block;
          width: auto;
        }
      }

      .tasklike-top-info-phone {
        .top-info {
          padding: 0.25em 0.4em;
          // margin: 0 -0.6em 0 -0.3em;
          display: flex;
          align-items: center;
          min-height: 3.5em;

          .mat-select-trigger {
            line-height: 3em;
            height: auto;
          }

          .mat-form-field {
            display: block;
          }

          .prefix-label {
            width: 5.6em;
          }

          .select {
            flex-grow: 1;

            .mat-form-field-appearance-legacy .mat-form-field-wrapper .mat-form-field-infix {
              width: 100%;
            }

            &.isValid {
              .mat-form-field-appearance-legacy {
                .mat-form-field-wrapper {
                  .mat-form-field-underline {
                    opacity: 0;
                  }

                  .mat-select-arrow-wrapper {
                    opacity: 0.25;
                  }

                  &:hover {
                    .mat-form-field-underline {
                      opacity: 0.1;
                    }

                    .mat-select-arrow-wrapper {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          .input {
            flex-grow: 1;
          }

          .ico.prefix {
            padding-right: 0.5em;
          }
        }

        .top-info:nth-child(odd) {
          background: $col-hover;
        }

        .latest-user {
          padding: 0;
          margin: 0 0 0 0 !important;
          text-align: right;
          display: block;

          .ico {
            margin-right: 0.25em;
            position: relative;
            top: 0.3rem;
          }
        }
      }
    }

    .tasklike-tab-container {
      overflow: hidden;

      .mat-tab-labels {
        padding: 0;
      }

      .mat-tab-header {
        display: flex;
        overflow: hidden;
        flex-shrink: 0;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        bottom: 3.4em;
        background: $col-backcontrast;
        padding: 0 0 0 0em;
        border: 0 none;
        box-shadow: 0 0 4px $col-border;

        .mat-tab-header-pagination {
          display: none !important;
        }
      }

      .mat-tab-group {
        min-height: calc(100vh - 3em);
      }

      .mat-tab-body.mat-tab-body-active {
        animation: mobileAppear 0.2s linear forwards;
        transform-origin: top center;
      }

      .mat-tab-body-wrapper {
        height: auto;
      }

      .mat-tab-body-wrapper .mat-tab-body-content {
        height: auto;
        padding: 0;
      }

      .mat-ink-bar {
        visibility: hidden !important;
      }

      .mat-tab-label {
        border: 1px solid $col-border;
        margin: 0 0 0 -1px;
        flex-grow: 1;
        border-radius: 0;
        opacity: 1;
        background-color: $col-backcontrast !important;

        &.mat-tab-label-active {
          background-color: $col-primary !important;
          color: $col-backcontrast !important;
          border-left-color: $col-border !important;
          border-bottom-color: $col-primary !important;
        }
      }

      .c4-docgrid-container {
        font-size: 3.2vw;
      }

      .tasklike-detail-content {
        padding: 0.5em 0 6.4em 0;
        display: block;

        .p-editor-toolbar.ql-toolbar.ql-snow {
          display: none;
        }

        .p-editor-content.ql-container.ql-snow {
          font-size: inherit;
          height: 12.5em !important;
          line-height: 1.25em;

          .ql-editor {
            line-height: inherit;
            padding: 0.2em 0.4em;
          }
        }

        .mat-expansion-panel-content {
          font-size: 4vw !important;
        }

        .content-container.content-a {
          min-width: 50%;
          width: auto;
          max-width: 100vw;
          padding: 0 2px 0 0;
        }

        .content-container ul li .comment {
          display: block;
          padding: 0.25em 0.5em;

          .header {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.9em;
            opacity: 0.5;

            span:first-child {
              color: $col-text;
            }
          }

          .buttons {
            display: block;
            margin: 0;
            text-align: right;
          }
        }
      }

      // .history-list .history-list-item {
      //   display: block;
      //   padding: 0.5em;
      //   margin: 0.5em 0 1em;
      //   background: $col-hover;
      //   border-radius: 0.3em;
      //   .history-date {
      //     min-width: 0;
      //     display: inline-block;
      //     opacity: 0.5;
      //     padding: 0 0 0.3em 0;
      //   }
      //   .history-user {
      //     min-width: 0;
      //     display: inline-block;
      //     padding: 0 0 0.3em 1em;
      //   }
      //   .change-table {
      //     border-collapse: collapse;
      //   }
      // }
    }

    .c4-document-browser.integrated {
      .c4-docgrid-container {
        padding: 1.5em 0 1em 0;

        .p-datatable-thead {
          position: sticky;
          top: 3em;
          background: $col-backcontrast;
          z-index: 98;
          box-shadow: 0 1px 0 0 $col-selected;
        }
      }

      .integrated-button-container {
        .c4-upload-ctrl {
          margin-top: 1em;
          width: 100%;

          button {
            width: 100%;
          }

          .mat-button-wrapper {
            width: 100%;
            min-width: 0;
          }
        }
      }
    }

    .tasklike-dialog-title .dialog-top {
      overflow: hidden;

      .dialog-controls {
        position: absolute;
        z-index: 99;
        top: 0;
        // padding-top: env(safe-area-inset-top);
        right: 0;

        .mat-icon-button {
          width: 2.2em;
          height: 2.2em;
          line-height: unset;
          border-radius: 0;
          font-size: inherit;

          .mat-button-wrapper {
            .ctrl-ico::before {
              font-size: 2.2em;
            }
          }
        }
      }
    }
  }
}

@keyframes mobileAppear {
  0% {
    opacity: 0.1;
    transform: translateY(25%) scale(0.6);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%) scale(1);
  }
}